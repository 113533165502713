import React, { Component } from "react";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import MetaDecorator from "./MetaDecorator";
import metaThumbnail from "../images/bachericonmetathumbnail.jpg";

function RenderObjectsCard({ object }) {
  const isMobile = useMediaQuery({ query: "(max-width: 685px)" });
  const isSmallMobile = useMediaQuery({ query: "(max-width: 500px)" });

  return isSmallMobile === true ? (
    <div
      className="container-fluid max-width: 100%"
      id={object.id}
      style={{
        paddingLeft: "0px",
        paddingRight: "0px",
        overflow: "hidden",
      }}
    >
      <div
        className={"row row-content-grey"}
        style={{
          padding: "0px",
        }}
      >
        <div className="col">
          <div className="row" style={{ height: "250px" }}>
            <div
              className="col-12"
              style={{
                backgroundImage: "url(" + object.image + ")",
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                height: "250px",
              }}
            />
          </div>
          <div
            className="row"
            style={{
              paddingLeft: "10px",
              paddingRight: "10px",
              paddingTop: "20px",
              paddingBottom: "40px",
            }}
          >
            <div
              className="col-12"
              style={{
                justifyContent: "center",
                alignSelf: "center",
              }}
            >
              <div className="row row-content-nopadding">
                <div className="col">
                  <h3
                    style={{
                      color: "#000000",
                      fontWeight: "bold",
                      fontFamily: "Avenir",
                    }}
                  >
                    {object.name}
                  </h3>
                </div>
              </div>
              <div className="row row-content-nopadding">
                <div className="col">
                  <p
                    style={{
                      color: "black",
                      fontFamily: "Avenir",
                    }}
                  >
                    {object.address}
                  </p>
                </div>
              </div>
              <div className="row row-content-nopadding">
                <div className="col">
                  <p style={{ fontFamily: "Avenir" }}>"{object.description}"</p>
                </div>
              </div>
              <div className="row row-content-nopadding">
                <div className="col">
                  <Link
                    to={"/objects/" + object.link}
                    style={{ color: "white", fontFamily: "Avenir" }}
                  >
                    <Button
                      style={{
                        backgroundColor: "#C83743",
                        borderColor: "#C83743",
                        height: "48px",
                        width: "200px",
                      }}
                      size="lg"
                    >
                      {object.objectStatus === "rent"
                        ? "Wohnung mieten"
                        : "Erfahre mehr"}
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div
      className="container-fluid max-width: 100%"
      id={object.id}
      style={{
        paddingLeft: "0px",
        paddingRight: "0px",
        overflow: "hidden",
      }}
    >
      <div className={"row row-content-grey"} style={{ padding: "0px" }}>
        {object.id % 2 !== 0 ? (
          <React.Fragment>
            <div
              className="col-6"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "0px",
              }}
            >
              <picture>
                <source
                  srcset={object.imageWebP}
                  type="image/webp"
                  height={object.height}
                  width={object.width}
                />
                <img
                  src={object.image}
                  alt={object.title}
                  height={object.height}
                  width={object.width}
                />
              </picture>
            </div>
            <div
              className="col-6"
              style={{
                justifyContent: "center",
                alignSelf: "center",
              }}
            >
              <div
                className={
                  isMobile === true
                    ? "row row-content-nopadding"
                    : "row row-content-padding"
                }
              >
                <div className="col">
                  {isMobile === true ? (
                    <h3
                      style={{
                        color: "#000000",
                        fontWeight: "bold",
                        fontFamily: "Avenir",
                      }}
                    >
                      {object.name}
                    </h3>
                  ) : (
                    <h1
                      style={{
                        color: "#000000",
                        fontWeight: "bold",
                        fontFamily: "Avenir",
                      }}
                    >
                      {object.name}
                    </h1>
                  )}
                </div>
              </div>
              <div
                className={
                  isMobile === true
                    ? "row row-content-nopadding"
                    : "row row-content-padding"
                }
              >
                <div className="col">
                  <p
                    style={{
                      color: "black",
                      fontFamily: "Avenir",
                    }}
                  >
                    {object.address}
                  </p>
                </div>
              </div>
              <div
                className={
                  isMobile === true
                    ? "row row-content-nopadding"
                    : "row row-content-padding"
                }
              >
                <div className="col">
                  <p style={{ fontFamily: "Avenir" }}>"{object.description}"</p>
                </div>
              </div>
              <div
                className={
                  isMobile === true
                    ? "row row-content-nopadding"
                    : "row row-content-padding"
                }
              >
                <div className="col">
                  <Link
                    to={"/objects/" + object.link}
                    style={{ color: "white", fontFamily: "Avenir" }}
                  >
                    <Button
                      style={{
                        backgroundColor: "#C83743",
                        borderColor: "#C83743",
                        height: "48px",
                        width: "200px",
                      }}
                      size="lg"
                    >
                      {object.objectStatus === "rent"
                        ? "Wohnung mieten"
                        : "Erfahre mehr"}
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div
              className="col-6"
              style={{
                justifyContent: "center",
                alignSelf: "center",
              }}
            >
              <div
                className={
                  isMobile === true
                    ? "row row-content-nopadding"
                    : "row row-content-padding"
                }
              >
                <div className="col">
                  {isMobile === true ? (
                    <h3
                      style={{
                        color: "#000000",
                        fontWeight: "bold",
                        fontFamily: "Avenir",
                      }}
                    >
                      {object.name}
                    </h3>
                  ) : (
                    <h1
                      style={{
                        color: "#000000",
                        fontWeight: "bold",
                        fontFamily: "Avenir",
                      }}
                    >
                      {object.name}
                    </h1>
                  )}
                </div>
              </div>
              <div
                className={
                  isMobile === true
                    ? "row row-content-nopadding"
                    : "row row-content-padding"
                }
              >
                <div className="col">
                  <p
                    style={{
                      color: "black",
                      fontFamily: "Avenir",
                    }}
                  >
                    {object.address}
                  </p>
                </div>
              </div>
              <div
                className={
                  isMobile === true
                    ? "row row-content-nopadding"
                    : "row row-content-padding"
                }
              >
                <div className="col">
                  <p style={{ fontFamily: "Avenir" }}>"{object.description}"</p>
                </div>
              </div>
              <div
                className={
                  isMobile === true
                    ? "row row-content-nopadding"
                    : "row row-content-padding"
                }
              >
                <div className="col">
                  <Link
                    to={"/objects/" + object.link}
                    style={{ color: "white", fontFamily: "Avenir" }}
                  >
                    <Button
                      style={{
                        backgroundColor: "#C83743",
                        borderColor: "#C83743",
                        height: "48px",
                        width: "200px",
                      }}
                      size="lg"
                    >
                      {object.objectStatus === "rent"
                        ? "Wohnung mieten"
                        : "Erfahre mehr"}
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-6"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "0px",
              }}
            >
              <picture>
                <source
                  srcset={object.imageWebP}
                  type="image/webp"
                  height={object.height}
                  width={object.width}
                />
                <img
                  src={object.image}
                  alt={object.title}
                  height={object.height}
                  width={object.width}
                />
              </picture>
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
}

function ObjectsCard(props) {
  const isMobile = useMediaQuery({ query: "(max-width: 685px)" });

  const objectscard = props.objects.map((object) => {
    return (
      <div key={object.id} className="col-12">
        <RenderObjectsCard object={object} />
      </div>
    );
  });

  return (
    <div
      className="container-fluid max-width: 100%"
      style={{
        paddingLeft: "0px",
        paddingRight: "0px",
        overflow: "hidden",
      }}
    >
      <div className="row">
        <div
          className="col-6"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            paddingTop: "20px",
            paddingBottom: "20px",
            background: "#DB233D",
          }}
        >
          <picture>
            <source
              srcset="/assets/images/bachericonredcropped.webp"
              type="image/webp"
              height="100%"
              width={isMobile === true ? "40%" : "20%"}
            />
            <img
              src="/assets/images/bachericonred.jpg"
              alt="bachericonredcomplete"
              height="100%"
              width="20%"
            />
          </picture>
        </div>
        <div
          className="col-6"
          style={{
            display: "flex",
            justifyContent: "center",
            alignSelf: "center",
          }}
        >
          {isMobile === true ? (
            <h3
              style={{
                color: "#000000",
                fontWeight: "bold",
                fontFamily: "Avenir",
              }}
            >
              Mieten oder Kaufen.
            </h3>
          ) : (
            <h1
              style={{
                color: "#000000",
                fontWeight: "bold",
                fontFamily: "Avenir",
              }}
            >
              Mieten oder Kaufen.
            </h1>
          )}
        </div>
      </div>
      <div className="row"> {objectscard} </div>
    </div>
  );
}

class Objects extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div
        className="container-fluid max-width: 100%"
        style={{
          paddingLeft: "0px",
          paddingRight: "0px",
          overflow: "hidden",
        }}
      >
        <MetaDecorator
          title="Objekte - BS-Wohnbau Ingolstadt GmbH"
          description="Entdecken Sie Ihre neue Wohnung in Ingolstadt."
          imageUrl={metaThumbnail}
          imageAlt="Bacher Icon Complete"
        />
        <ObjectsCard objects={this.props.objects} />
      </div>
    );
  }
}

export default Objects;
