import React from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

function Footer(props) {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const footerStyle = {
    color: "black",
    fontSize: "20px",
    paddingLeft: "0px",
    fontFamily: "Avenir",
  };
  // const outerCardStyle = `
  //     padding: 0;
  //     margin: 0;
  //     box-shadow: 0px 5px 35px 0px rgba(50, 50, 93, 0.17);
  //   `;
  // const subContainerStyle = `
  //     box-shadow: 0 2px 5px -1px rgba(50,50,93,.25), 0 1px 3px -1px rgba(0,0,0,.3);
  //     align-items: center;
  //   `;
  // const subInputStyle = `
  //     font-family: 'Avenir', Arial, Helvetica, sans-serif;
  //     border-width: 0;
  //     margin: 0;
  //   `;
  // const subButtonStyle = `
  //     font-family: 'Avenir', Arial, Helvetica, sans-serif;
  //     border-radius: 0;
  //     flex-grow: 1;
  //     background-color: #fdfdfd;
  //     color: #000000;
  //   `;
  // const titleStyle = `
  //     font-family: 'Avenir', Arial, Helvetica, sans-serif;
  //     font-size: 30px;
  //     font-weight: 800;
  //     margin: 0 0 10px 0;
  //     text-align: left;
  //     word-break: break-word;
  // `;
  // const descriptionStyle = `
  //     font-family: 'Avenir', Arial, Helvetica, sans-serif;
  //     font-size: 15px;
  //     font-weight: 400;
  //     line-height: 21px;
  //     margin: 0 0 10px 0;
  //     text-align: left;
  // `;

  return isMobile === false ? (
    <footer className="site-footer">
      <div className="container fluid">
        <div className="row">
          {/* <div className="col"> */}
          <div
            // className={"col-md-4 text-center"}
            className="col-4"
            style={{
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              className="col"
              style={{ paddingTop: "40px", paddingBottom: "10px" }}
            >
              <div style={{ width: "180px" }}>
                <picture>
                  <source
                    srcset="/assets/images/bachericonredcomplete.webp"
                    type="image/webp"
                    width="180px"
                  />
                  <img
                    src="/assets/images/bachericonredcomplete.jpg"
                    // height="80px"
                    width="180px"
                    textAlign="left"
                    alt="Bacher Icon Red Complete"
                  />
                </picture>
              </div>
              <p
                style={{
                  textAlign: "left",
                  fontSize: "12px",
                  width: "180px",
                  paddingTop: "10px",
                }}
              >
                Copyright © 2023 BS-Wohnbau Ingolstadt GmbH.
                <br />
                All rights reserved.
              </p>
            </div>
            {/* <div
              className="col"
              style={{ paddingTop: "40px", paddingBottom: "10px" }}
            >
              <h4 style={{ fontFamily: "Avenir" }}>Social Media</h4>
            </div>
            <div>
              <a
                className="btn-outline-color"
                href="http://instagram.com/live.ly.shopping"
              >
                <i className="fa fa-instagram fa-lg" />{" "}
              </a>
              <a
                className="btn-outline-color"
                href="https://www.facebook.com/BACHER.hochundtiefbau/"
              >
                <i className="fa fa-facebook fa-lg" />{" "}
              </a>
              <a
                className="btn-outline-color"
                href="https://de.linkedin.com/in/arno-schmidt-a35a3a130"
              >
                <i className="fa fa-linkedin fa-lg" />{" "}
              </a>
            </div> */}
          </div>
          <div
            className={
              // "col-md-4" + (isMobile !== true ? "text-left" : "text-center")
              "col-4"
            }
            style={{
              textAlign: isMobile !== true ? "left" : "center",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              className="col"
              style={{ paddingTop: "40px", paddingBottom: "10px" }}
            >
              <h4 style={{ fontFamily: "Avenir" }}>Rechtliches</h4>
            </div>
            <div
              className="col"
              style={
                isMobile === true
                  ? { paddingTop: "6px", paddingBottom: "12px" }
                  : { paddingTop: "6px", paddingBottom: "12px" }
              }
            >
              <Link to="/privacypolicy" style={footerStyle}>
                Datenschutzerklärung
              </Link>
            </div>
            <div className="col">
              <Link to="/imprint" style={footerStyle}>
                Impressum
              </Link>
            </div>
          </div>
          <div
            className={
              // "col-md-4" + (isMobile !== true ? "text-left" : "text-center")
              "col-4"
            }
            style={{
              textAlign: isMobile !== true ? "left" : "center",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              className="col"
              style={{ paddingTop: "40px", paddingBottom: "10px" }}
            >
              <h4 style={{ fontFamily: "Avenir" }}>Kontakt</h4>
            </div>
            <div className="col">
              <a
                role="button"
                className="btn btn-link"
                href="mailto:info@bsw-in.de"
                style={footerStyle}
              >
                info@bsw-in.de
              </a>
            </div>
            <div className="col">
              <a
                role="button"
                className="btn btn-link"
                href="tel:0841955920"
                style={footerStyle}
              >
                0841 95592-0
              </a>
            </div>
          </div>
          {/* <div
            className={"col-md-4 text-center"}
            style={{
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              className="col"
              style={{ paddingTop: "40px", paddingBottom: "10px" }}
            >
              <picture> */}
          {/* <source
                  srcset="/assets/images/bachericoncomplete.webp"
                  type="image/webp"
                /> */}
          {/* <img
                  src="/assets/images/bachericonredcomplete.jpg"
                  height="100px"
                  alt="Bacher Icon Red Complete"
                />
              </picture>
            </div> */}
          {/* <div
              className="col"
              style={{ paddingTop: "40px", paddingBottom: "10px" }}
            >
              <h4 style={{ fontFamily: "Avenir" }}>Social Media</h4>
            </div>
            <div>
              <a
                className="btn-outline-color"
                href="http://instagram.com/live.ly.shopping"
              >
                <i className="fa fa-instagram fa-lg" />{" "}
              </a>
              <a
                className="btn-outline-color"
                href="https://www.facebook.com/BACHER.hochundtiefbau/"
              >
                <i className="fa fa-facebook fa-lg" />{" "}
              </a>
              <a
                className="btn-outline-color"
                href="https://de.linkedin.com/in/arno-schmidt-a35a3a130"
              >
                <i className="fa fa-linkedin fa-lg" />{" "}
              </a>
            </div> */}
          {/* </div> */}
        </div>
        {/* </div> */}
      </div>
    </footer>
  ) : (
    <footer className="site-footer">
      <div className="container fluid">
        {/* <div className="row">
          <div
            className="col"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <SubscribeCard
              title="Werde Teil der Community."
              description="Durch unseren Newsletter halten wir dich auf dem Laufenden und erleichtern dir das Vernetzen innerhalb der Community."
              emailPlaceholder="Deine E-Mail Adresse"
              buttonText="Verbinden"
              mailchimpURL={process.env.REACT_APP_MAILCHIMP_URL}
              outerCardStyle={outerCardStyle}
              subContainerStyle={subContainerStyle}
              subInputStyle={subInputStyle}
              subButtonStyle={subButtonStyle}
              titleStyle={titleStyle}
              descriptionStyle={descriptionStyle}
            />
          </div>
        </div>
        <hr style={{ marginTop: "40px" }} /> */}
        <div className="row">
          {/* <div
            className={"col-md-4 text-center"}
            style={{
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              className="col"
              style={{ paddingTop: "40px", paddingBottom: "10px" }}
            >
              <h4 style={{ fontFamily: "Avenir" }}>Social Media</h4>
            </div>
            <div>
              <a
                className="btn-outline-color"
                href="http://instagram.com/live.ly.shopping"
              >
                <i className="fa fa-instagram fa-lg" />{" "}
              </a>
              <a
                className="btn-outline-color"
                href="https://www.facebook.com/livelyshopping-105337131201376"
              >
                <i className="fa fa-facebook fa-lg" />{" "}
              </a>
              <a
                className="btn-outline-color"
                href="https://twitter.com/LivelyShopping"
              >
                <i className="fa fa-twitter fa-lg" />{" "}
              </a>
              <a
                className="btn-outline-color"
                href="https://www.linkedin.com/company/lively-shopping/"
              >
                <i className="fa fa-linkedin fa-lg" />{" "}
              </a>
            </div>
          </div> */}
          <div
            className={
              "col-12"
              // + (isMobile !== true ? "text-left" : "text-center")
            }
            style={{
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              className="col"
              style={{ paddingTop: "40px", paddingBottom: "10px" }}
            >
              <h4 style={{ fontFamily: "Avenir" }}>Kontakt</h4>
            </div>
            <div className="col">
              <a
                role="button"
                className="btn btn-link"
                href="mailto:info@bsw-in.de"
                style={footerStyle}
              >
                info@bsw-in.de
              </a>
            </div>
            <div className="col">
              <a
                role="button"
                className="btn btn-link"
                href="tel:0841955920"
                style={footerStyle}
              >
                0841 95592-0
              </a>
            </div>
          </div>
          <div
            className={
              "col-12"
              //  + (isMobile !== true ? "text-left" : "text-center")
            }
            style={{
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              className="col"
              style={{ paddingTop: "40px", paddingBottom: "10px" }}
            >
              <h4 style={{ fontFamily: "Avenir" }}>Rechtliches</h4>
            </div>
            <div
              className="col"
              style={
                isMobile === true
                  ? { paddingBottom: "12px" }
                  : { paddingBottom: "0px" }
              }
            >
              <Link to="/privacypolicy" style={footerStyle}>
                Datenschutzrichtlinie
              </Link>
            </div>
            <div className="col">
              <Link to="/imprint" style={footerStyle}>
                Impressum
              </Link>
            </div>
          </div>
          <div
            className="col-12"
            style={{
              textAlign: "center",
              paddingTop: "40px",
              paddingBottom: "10px",
            }}
          >
            <div>
              <picture>
                <source
                  srcset="/assets/images/bachericonredcomplete.webp"
                  type="image/webp"
                  width="180px"
                />
                <img
                  src="/assets/images/bachericonredcomplete.jpg"
                  // height="80px"
                  width="180px"
                  textAlign="center"
                  alt="Bacher Icon Red Complete"
                />
              </picture>
            </div>
            <p
              style={{
                textAlign: "center",
                fontSize: "12px",
                paddingTop: "10px",
              }}
            >
              Copyright © 2023 BS-Wohnbau Ingolstadt GmbH.
              <br />
              All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
