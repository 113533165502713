import React, { Component } from "react";
import Header from "./HeaderComponent";
import Footer from "./FooterComponent";
import Home from "./HomeComponent";
import Objects from "./ObjectsComponent";
import ObjectsInfo from "./ObjectsInfoComponent";
import Contact from "./ContactComponent";
import RentalRequest from "./RentalRequestComponent";
import Privacy from "./PrivacyComponent";
import Imprint from "./ImprintComponent";
import { Switch, Route, Redirect } from "react-router-dom";
import { REFERENCES } from "../shared/customerreferences";
import { OBJECTS } from "../shared/objects";

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      references: REFERENCES,
      objects: OBJECTS,
    };
  }

  render() {
    const HomePage = () => {
      return <Home references={this.state.references} />;
    };

    const ObjectWithLink = ({ match }) => {
      return (
        <ObjectsInfo
          objects={
            this.state.objects.filter(
              (object) => object.link === match.params.objectLink
            )[0]
          }
        />
      );
    };

    const RentalRequestWithLink = ({ match }) => {
      return (
        <RentalRequest
          objects={
            this.state.objects.filter(
              (object) => object.link === match.params.objectLink
            )[0]
          }
        />
      );
    };

    return (
      <div>
        <Header />
        <Switch>
          <Route
            path="/home"
            references={this.state.references}
            component={HomePage}
          />
          <Route
            exact
            path="/objects"
            render={() => <Objects objects={this.state.objects} />}
          />
          <Route path="/objects/:objectLink" component={ObjectWithLink} />
          <Route
            path="/rentalRequest/:objectLink"
            component={RentalRequestWithLink}
          />
          <Route exact path="/contact" render={() => <Contact />} />
          <Route exact path="/privacypolicy" render={() => <Privacy />} />
          <Route exact path="/imprint" render={() => <Imprint />} />
          <Redirect to="/home" />
        </Switch>
        <Footer />
      </div>
    );
  }
}

export default Main;
