import React from "react";
import { BrowserRouter } from "react-router-dom";
import Main from "./components/MainComponent";
import ScrollToTop from "./components/ScrollComponent";
import "./App.css";

function App() {
  return (
    <React.StrictMode>
      <BrowserRouter>
        <div className="App">
          <ScrollToTop />
          <Main />
        </div>
      </BrowserRouter>
    </React.StrictMode>
  );
}

export default App;
