import React from "react";
import { Helmet } from "react-helmet";

const MetaDecorator = ({ title, description, imageUrl, imageAlt }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta
        property="og:image"
        content={"https://www.bswohnbau-in.de" + imageUrl}
      />
      <meta
        property="og:url"
        content={
          "https://www.bswohnbau-in.de" +
          window.location.pathname +
          window.location.search
        }
      />
      <meta name="twitter:card" content="summary_large_image" />

      <meta property="og:site_name" content="BSWohnbau-In.de" />
      <meta name="twitter:image:alt" content={imageAlt} />
    </Helmet>
  );
};

export default MetaDecorator;
