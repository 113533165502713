import React, { Component } from "react";
import { useState, useEffect } from "react";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import "react-tabs/style/react-tabs.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
// import { FontAwesomeIcon } from "fontawesome/react-fontawesome";
// import "font-awesome/css/font-awesome.min.css";
import MetaDecorator from "./MetaDecorator";
import metaThumbnail from "../images/bachericonmetathumbnail.jpg";
import { GDPRCoOv, GDPRCoOvConfig, IframeSettings } from "gdpr-c-o";

function ObjectsPreview({ objects }) {
  const [anchorTarget, setAnchorTarget] = useState(null);

  useEffect(() => {
    setAnchorTarget(document.getElementById("apartmentInfo"));
  }, []);

  const handleClick = (event) => {
    event.preventDefault();
    anchorTarget.scrollIntoView({
      behavior: "smooth",
      // block: "start",
      block: "center",
    });
  };

  return (
    <div
      className="container-fluid max-width: 100%"
      id={objects.id}
      style={{
        backgroundImage: "url(" + objects.image + ")",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: 700,
        justifyContent: "center",
        verticalAlign: "center",
        alignSelf: "center",
        paddingLeft: "0px",
        paddingRight: "0px",
        overflow: "hidden",
      }}
    >
      <div
        className="col"
        style={{
          position: "relative",
          top: "0px",
          left: "0px",
          height: "100%",
          width: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.6)",
          margin: "0px",
        }}
      >
        <div
          className="row row-content-padding"
          style={{
            height: 700,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            verticalAlign: "center",
            padding: "35px 0",
          }}
        >
          <React.Fragment>
            <div
              className="col"
              style={{
                justifyContent: "center",
                alignSelf: "center",
              }}
            >
              <div className="row row-content-padding">
                <div className="col">
                  <h1
                    style={{
                      color: "white",
                      fontWeight: "bold",
                      fontFamily: "Avenir",
                    }}
                  >
                    {objects.name}
                  </h1>
                </div>
              </div>
              <div className="row row-content-padding">
                <div className="col">
                  <p
                    style={{
                      color: "white",
                      fontFamily: "Avenir",
                    }}
                  >
                    {objects.address}
                  </p>
                </div>
              </div>
              <div className="row row-content-padding">
                <div className="col">
                  <p style={{ color: "white", fontFamily: "Avenir" }}>
                    "{objects.description}"
                  </p>
                </div>
              </div>
              <div className="row row-content-padding">
                <div className="col">
                  {objects.objectStatus === "rent" ? (
                    <Link
                      to="#apartmentInfo"
                      onClick={handleClick}
                      style={{
                        color: "white",
                        fontFamily: "Avenir",
                        marginTop: "20px",
                      }}
                    >
                      <Button
                        style={{
                          backgroundColor: "#C83743",
                          borderColor: "#C83743",
                          height: "48px",
                          width: "200px",
                        }}
                        size="lg"
                      >
                        Wohnung Mieten
                      </Button>
                    </Link>
                  ) : objects.objectStatus === "purchase" ? (
                    <Link
                      to="/contact"
                      style={{
                        color: "white",
                        fontFamily: "Avenir",
                        marginTop: "20px",
                      }}
                    >
                      <Button
                        style={{
                          backgroundColor: "#C83743",
                          borderColor: "#C83743",
                          height: "48px",
                          width: "280px",
                        }}
                        size="lg"
                      >
                        Kaufinteresse vormerken
                      </Button>
                    </Link>
                  ) : (
                    <React.Fragment />
                  )}
                </div>
              </div>
            </div>
            {/* <div className="col" /> */}
            {/* <div
              className="col-6"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "0px",
              }}
            > */}
            {/* <picture>
              <source
                width={
                  isMobile === true
                    ? isSmallMobile === true
                      ? objects.mobileSmallWidth
                      : objects.mobileWidth
                    : objects.width
                }
                srcset={objects.imageWebP}
                type="image/webp"
              />
              <img
                height={objects.height}
                width={objects.width}
                src={objects.image}
                alt={objects.title}
              />
            </picture> */}
            {/* </div> */}
          </React.Fragment>
        </div>
      </div>
    </div>
  );
}

function ProjectPreview({ objects }) {
  const isMobile = useMediaQuery({ query: "(max-width: 685px)" });

  const purchaseReasonItem = objects.purchaseReasons.map((object) => {
    return <li>{object}</li>;
  });

  return objects.purchaseReasons.length === 0 ? (
    <React.Fragment />
  ) : isMobile === true ? (
    <div
      className="container-fluid max-width: 100%"
      style={{
        // height: 350,
        justifyContent: "center",
        alignSelf: "center",
        backgroundColor: "#ffffff",
      }}
    >
      <div className="row">
        <div
          className="col"
          style={{
            padding: "25px",
            marginLeft: "50px",
            marginRight: "50px",
            marginTop: "50px",
            marginBottom: "25px",
            justifyContent: "center",
            backgroundColor: "#E4E5E6",
          }}
        >
          <h3>Unser Projekt.</h3>
          <p>{objects.projectDescription}</p>
        </div>
      </div>
      <div className="row">
        <div
          className="col"
          style={{
            padding: "25px",
            marginLeft: "50px",
            marginRight: "50px",
            marginTop: "25px",
            marginBottom: "50px",
            justifyContent: "center",
            backgroundColor: "#E4E5E6",
          }}
        >
          <h3>
            {objects.objectStatus === "rent"
              ? "Gründe zum Mieten."
              : "Gründe zum Kaufen."}
          </h3>
          <ul style={{ paddingLeft: "20px" }}>{purchaseReasonItem}</ul>
        </div>
      </div>
    </div>
  ) : (
    <div
      className="container-fluid max-width: 100%"
      style={{
        // height: 350,
        justifyContent: "center",
        alignSelf: "center",
        backgroundColor: "#ffffff",
      }}
    >
      <div className="row">
        <div
          className="col"
          style={{
            padding: "25px",
            marginTop: "100px",
            marginBottom: "100px",
            marginLeft: "100px",
            marginRight: "20px",
            justifyContent: "center",
            backgroundColor: "#E4E5E6",
          }}
        >
          <h3>Unser Projekt.</h3>
          <p>{objects.projectDescription}</p>
        </div>
        <div
          className="col"
          style={{
            padding: "25px",
            marginTop: "100px",
            marginBottom: "100px",
            marginLeft: "20px",
            marginRight: "100px",
            justifyContent: "center",
            backgroundColor: "#E4E5E6",
          }}
        >
          <h3>
            {objects.objectStatus === "rent"
              ? "Gründe zum Mieten."
              : "Gründe zum Kaufen."}
          </h3>
          <ul style={{ paddingLeft: "20px" }}>{purchaseReasonItem}</ul>
        </div>
      </div>
    </div>
  );
}

function ObjectOutdoorCarouselPreview({ objects }) {
  const carouselItem = objects.objectOutdoorImages.map((object) => {
    return (
      <div
        className="col-12"
        style={{
          backgroundImage: "url(" + object + ")",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: 600,
        }}
      />
    );
  });

  return (
    <div
      className="container-fluid max-width: 100%"
      style={{
        // height: 350,
        justifyContent: "center",
        alignSelf: "center",
        backgroundColor: "#ffffff",
      }}
    >
      <div className="row">
        <div className="col-12" style={{ padding: "0px" }}>
          <Carousel
            autoPlay={true}
            infiniteLoop={true}
            interval="5000"
            showStatus={false}
            showThumbs={false}
          >
            {carouselItem}
          </Carousel>
        </div>
      </div>
    </div>
  );
}

function ContactPreview({ objects }) {
  const isMobile = useMediaQuery({ query: "(max-width: 685px)" });

  // return objects.apartmentOverview.length === 0 ? (
  return isMobile === true ? (
    <div
      className="container-fluid max-width: 100%"
      style={{
        backgroundColor:
          objects.objectIndoorImages.length === 0 ? "#FFFFFF" : "#E4E5E6",
        justifyContent: "center",
        verticalAlign: "center",
        alignSelf: "center",
        padding: "0px",
      }}
    >
      <div className="row" style={{ padding: "0px" }}>
        <div className="col-1" />
        {objects.objectStatus === "rent" ? (
          <div
            className="col-10"
            style={{
              // backgroundColor: "#E4E5E6",
              padding: "40px",
            }}
          >
            <h1
              style={{
                color: "black",
                fontWeight: "bold",
                fontFamily: "Avenir",
                textAlign: "center",
                marginTop: "40px",
              }}
            >
              Interesse geweckt?
            </h1>
            <div
              className="row"
              style={{
                justifyContent: "center",
                verticalAlign: "center",
                display: "flex",
                alignItems: "center",
                alignSelf: "center",
              }}
            >
              {objects.objectBrochure === "" ? (
                <React.Fragment />
              ) : objects.objectStatus === "rent" ? (
                <Link
                  to={"/rentalRequest/" + objects.link}
                  style={{
                    color: "white",
                    fontFamily: "Avenir",
                    padding: "10px",
                  }}
                >
                  <Button
                    style={{
                      backgroundColor: "#C83743",
                      borderColor: "#C83743",
                      height: "48px",
                      width: "200px",
                    }}
                    size="lg"
                  >
                    Zur Mietanfrage
                  </Button>
                </Link>
              ) : (
                <React.Fragment />
              )}
              <Link
                to="/contact"
                style={{
                  color: "white",
                  fontFamily: "Avenir",
                  padding: "10px",
                }}
              >
                {objects.objectBrochure === "" ? (
                  <Button
                    style={{
                      backgroundColor: "#C83743",
                      borderColor: "#C83743",
                      height: "48px",
                      width: "200px",
                    }}
                    size="lg"
                  >
                    Kontakt
                  </Button>
                ) : (
                  <Button
                    style={{ height: "48px", width: "200px" }}
                    outline
                    color="dark"
                    size="lg"
                  >
                    Kontakt
                  </Button>
                )}
              </Link>
            </div>
          </div>
        ) : objects.objectStatus === "purchase" ? (
          <div
            className="col-5"
            style={{
              // backgroundColor: "#E4E5E6",
              padding: "50px",
            }}
          >
            <h1
              style={{
                color: "black",
                fontWeight: "bold",
                fontFamily: "Avenir",
                textAlign: "left",
                marginTop: "40px",
              }}
            >
              Realisierung ab 2024.
            </h1>
          </div>
        ) : (
          <div
            className="col-5"
            style={{
              // backgroundColor: "#E4E5E6",
              padding: "50px",
            }}
          >
            <h1
              style={{
                color: "black",
                fontWeight: "bold",
                fontFamily: "Avenir",
                textAlign: "left",
                marginTop: "40px",
              }}
            >
              Alle Wohnungen verkauft.
            </h1>
          </div>
        )}
        <div className="col-1" />
      </div>
      <div className="row" style={{ padding: "0px" }}>
        <div className="col-1" />
        <div
          className="col-10"
          style={{
            padding: "50px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <picture>
            <source
              srcset="/assets/images/bachericonblackcomplete.jpg"
              type="image/webp"
              width="100%"
            />
            <img
              src="/assets/images/bachericonblackcomplete.jpg"
              alt="Bacher Icon Complete Black"
              width="100%"
            />
          </picture>
        </div>
        <div className="col-1" />
      </div>
    </div>
  ) : (
    <div
      className="container-fluid max-width: 100%"
      style={{
        backgroundColor:
          objects.objectIndoorImages.length === 0 ? "#FFFFFF" : "#E4E5E6",
        justifyContent: "center",
        verticalAlign: "center",
        alignSelf: "center",
        padding: "0px",
      }}
    >
      <div className="row" style={{ padding: "0px" }}>
        <div className="col-1" />
        <div
          className="col-5"
          style={{
            padding: "50px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <picture>
            <source
              srcset="/assets/images/bachericonblackcomplete.jpg"
              type="image/webp"
              width="100%"
            />
            <img
              src="/assets/images/bachericonblackcomplete.jpg"
              alt="Bacher Icon Complete Black"
              width="100%"
            />
          </picture>
        </div>
        {objects.objectStatus === "rent" ? (
          <div
            className="col-5"
            style={{
              // backgroundColor: "#E4E5E6",
              padding: "50px",
            }}
          >
            <h1
              style={{
                color: "black",
                fontWeight: "bold",
                fontFamily: "Avenir",
                textAlign: "left",
                marginTop: "40px",
              }}
            >
              Interesse geweckt?
            </h1>
            <div className="row" style={{ padding: "5px", paddingTop: "10px" }}>
              {objects.objectBrochure === "" ? (
                <React.Fragment />
              ) : objects.objectStatus === "rent" ? (
                <Link
                  to={"/rentalRequest/" + objects.link}
                  style={{
                    color: "white",
                    fontFamily: "Avenir",
                    padding: "10px",
                  }}
                >
                  <Button
                    style={{
                      backgroundColor: "#C83743",
                      borderColor: "#C83743",
                      height: "48px",
                      width: "200px",
                    }}
                    size="lg"
                  >
                    Zur Mietanfrage
                  </Button>
                </Link>
              ) : (
                <React.Fragment />
              )}
              <Link
                to="/contact"
                style={{
                  color: "white",
                  fontFamily: "Avenir",
                  padding: "10px",
                }}
              >
                {objects.objectBrochure === "" ? (
                  <Button
                    style={{
                      backgroundColor: "#C83743",
                      borderColor: "#C83743",
                      height: "48px",
                      width: "200px",
                    }}
                    size="lg"
                  >
                    Kontakt
                  </Button>
                ) : (
                  <Button
                    style={{ height: "48px", width: "200px" }}
                    outline
                    color="dark"
                    size="lg"
                  >
                    Kontakt
                  </Button>
                )}
              </Link>
            </div>
          </div>
        ) : objects.objectStatus === "purchase" ? (
          <div
            className="col-5"
            style={{
              // backgroundColor: "#E4E5E6",
              padding: "50px",
            }}
          >
            <h1
              style={{
                color: "black",
                fontWeight: "bold",
                fontFamily: "Avenir",
                textAlign: "left",
                marginTop: "40px",
              }}
            >
              Realisierung ab 2024.
            </h1>
          </div>
        ) : (
          <div
            className="col-5"
            style={{
              // backgroundColor: "#E4E5E6",
              padding: "50px",
            }}
          >
            <h1
              style={{
                color: "black",
                fontWeight: "bold",
                fontFamily: "Avenir",
                textAlign: "left",
                marginTop: "40px",
              }}
            >
              Alle Wohnungen verkauft.
            </h1>
          </div>
        )}
        <div className="col-1" />
      </div>
    </div>
  );
}

function ObjectIndoorCarouselPreview({ objects }) {
  const carouselItem =
    objects.objectIndoorImages.length === 0 ? (
      <React.Fragment />
    ) : (
      objects.objectIndoorImages.map((object) => {
        return (
          <div
            className="col-12"
            style={{
              backgroundImage: "url(" + object + ")",
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              height: 600,
            }}
          />
        );
      })
    );

  return objects.objectIndoorImages.length === 0 ? (
    <React.Fragment />
  ) : (
    <div
      className="container-fluid max-width: 100%"
      style={{
        // height: 350,
        justifyContent: "center",
        alignSelf: "center",
        backgroundColor: "#ffffff",
      }}
    >
      <div className="row">
        <div className="col-12" style={{ padding: "0px" }}>
          <Carousel
            autoPlay={true}
            infiniteLoop={true}
            interval="5000"
            showStatus={false}
            showThumbs={false}
          >
            {carouselItem}
          </Carousel>
        </div>
      </div>
    </div>
  );
}

function ApartmentInfoItemPreview({ objects }) {
  // const isMobile = useMediaQuery({ query: "(max-width: 1005px)" });
  const isMediumMobile = useMediaQuery({ query: "(max-width: 855px)" });
  // const isSmallMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const isSmallMobile = useMediaQuery({ query: "(max-width: 700px)" });

  const carouselItem =
    objects.images.length === 0 ? (
      <React.Fragment />
    ) : isSmallMobile === true ? (
      objects.imagesMobile.map((object) => {
        return (
          <div className="col-12" style={{ height: 600, paddingTop: "40px" }}>
            <h3
              style={{
                color: "black",
                fontWeight: "bold",
                fontFamily: "Avenir",
              }}
            >
              {object.includes("EG")
                ? `Haus ${objects.houseNumber} - Erdgeschoss`
                : object.includes("1OG")
                ? `Haus ${objects.houseNumber} - Obergeschoss 1`
                : object.includes("2OG")
                ? `Haus ${objects.houseNumber} - Obergeschoss 2`
                : `Haus ${objects.houseNumber}`}
            </h3>
            <div
              className="col-12"
              style={{
                backgroundImage: "url(" + object + ")",
                backgroundPosition: "center",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                height: 480,
              }}
            />
          </div>
        );
      })
    ) : (
      objects.images.map((object) => {
        return (
          <div className="col-12" style={{ height: 600, paddingTop: "40px" }}>
            <h1
              style={{
                color: "black",
                fontWeight: "bold",
                fontFamily: "Avenir",
              }}
            >
              {object.includes("EG")
                ? `Haus ${objects.houseNumber} - Erdgeschoss`
                : object.includes("1OG")
                ? `Haus ${objects.houseNumber} - Obergeschoss 1`
                : object.includes("2OG")
                ? `Haus ${objects.houseNumber} - Obergeschoss 2`
                : `Haus ${objects.houseNumber}`}
            </h1>
            <div
              className="col-12"
              style={{
                backgroundImage: "url(" + object + ")",
                backgroundPosition: "center",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                height: 480,
              }}
            />
          </div>
        );
      })
    );

  const tableContent = objects.information.map((object) => {
    return (
      <tr>
        <td>Wohnung {object.apartmentNumber}</td>
        <td>{object.status === "verfügbar" ? object.floor : ""}</td>
        <td>{object.status === "verfügbar" ? object.rooms : ""}</td>
        <td>{object.status === "verfügbar" ? object.size : ""}</td>
        <td>{object.status === "verfügbar" ? object.coldRent : ""}</td>
        <td>
          {object.status === "verfügbar" ? object.undergroundParkingSpace : ""}
        </td>
        <td>{object.status === "verfügbar" ? object.parkingRent : ""}</td>
        <td>{object.status}</td>
      </tr>
    );
  });

  // const tableBlur = objects.information.map((object, index) => {
  //   const headerFactor = isMobile === true ? 2 : 1;
  //   const headerHeight =
  //     (100 / (objects.information.length + headerFactor)) * headerFactor;
  //   const topOffset =
  //     headerHeight +
  //     ((100 - headerHeight) / objects.information.length) * index;

  //   return object.status === "reserviert" ? (
  //     <div
  //       style={{
  //         position: "absolute",
  //         width: "100%",
  //         top: `${topOffset}%`,
  //         textAlign: "center",
  //         fontWeight: "bold",
  //         backdropFilter: "blur(2px)",
  //       }}
  //     >
  //       Reserviert
  //     </div>
  //   ) : (
  //     <React.Fragment />
  //   );
  // });

  return (
    <div
      className="container-fluid max-width: 100%"
      style={{
        justifyContent: "center",
        alignSelf: "center",
        backgroundColor: "#ffffff",
      }}
    >
      <div className="row">
        <div className="col-12">
          <Carousel
            autoPlay={true}
            infiniteLoop={true}
            interval="10000"
            showStatus={false}
            showThumbs={false}
          >
            {carouselItem}
          </Carousel>
        </div>
      </div>
      <div
        className="row"
        style={{
          backgroundColor: "#E4E5E6",
          paddingTop: "50px",
          paddingBottom: "50px",
        }}
      >
        <div className="col-12" style={{ padding: "0px", overflowX: "scroll" }}>
          <table
            width="100%"
            border="1"
            style={
              isMediumMobile === true
                ? { tableLayout: "auto" }
                : { tableLayout: "fixed" }
            }
          >
            <tr>
              <th scope="col">Whg. Nr.</th>
              <th scope="col">Stockwerk</th>
              <th scope="col">Zimmer</th>
              <th scope="col">Größe</th>
              <th scope="col">Kaltmiete</th>
              <th scope="col">TG Stellplatz</th>
              <th scope="col">SP Miete</th>
              <th scope="col">Status</th>
            </tr>
            {tableContent}
            {/* {tableBlur} */}
          </table>
        </div>
      </div>
      <div
        className="row"
        style={{
          backgroundColor: "#E4E5E6",
          paddingBottom: "50px",
        }}
      >
        <div className="col-12" style={{ textAlign: "center", padding: "0px" }}>
          <Link
            to={"/rentalRequest/" + objects.link}
            style={{ color: "white", fontFamily: "Avenir" }}
          >
            <Button
              style={{
                backgroundColor: "#C83743",
                borderColor: "#C83743",
                height: "48px",
                width: "200px",
              }}
              size="lg"
            >
              Zur Mietanfrage
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}

function ApartmentOverviewPreview({ objects }) {
  const isMobile = useMediaQuery({ query: "(max-width: 795px)" });
  const isMediumMobile = useMediaQuery({ query: "(max-width: 630px)" });
  const isSmallMobile = useMediaQuery({ query: "(max-width: 425px)" });

  const tabInfo = objects.apartmentOverview.map((object) => {
    const tabWidth = 100 / objects.apartmentOverview.length;
    return (
      <Tab
        style={{
          width: `${tabWidth}%`,
          fontSize: isSmallMobile === true ? "18px" : "24px",
          fontFamily: "Avenir",
        }}
      >
        Haus {object.houseNumber}
      </Tab>
    );
  });

  const tabContent = objects.apartmentOverview.map((object) => {
    return <TabPanel>{<ApartmentInfoItemPreview objects={object} />}</TabPanel>;
  });

  return (
    <div
      className="container-fluid max-width: 100%"
      id="apartmentInfo"
      style={{
        justifyContent: "center",
        alignSelf: "center",
        backgroundColor: "#E4E5E6",
      }}
    >
      <div className="row">
        <div className={isMobile === true ? "col-12" : "col-6"}>
          <div
            className="row"
            style={{
              paddingTop: "50px",
            }}
          >
            {isSmallMobile === true ? (
              <React.Fragment />
            ) : (
              <div className="col-1" />
            )}
            <div className="col">
              {isSmallMobile === true ? (
                <h3>Wohnungsübersicht.</h3>
              ) : (
                <h1>Wohnungsübersicht.</h1>
              )}
            </div>
            {isSmallMobile === true ? (
              <React.Fragment />
            ) : (
              <div className="col-1" />
            )}
          </div>
          <div
            className="row"
            style={{
              paddingTop: "50px",
              paddingBottom: "0px",
              paddingLeft:
                objects.purchaseReasons.length === 0 ||
                objects.apartmentOverview.length === 0
                  ? // objects.apartmentStatus.length === "infofollowing" ||
                    // objects.apartmentStatus.length === "apartmentsold"
                    "0px"
                  : isSmallMobile === true
                  ? "20px"
                  : "100px",
              paddingRight:
                objects.purchaseReasons.length === 0 ||
                objects.apartmentOverview.length === 0
                  ? // objects.apartmentStatus.length === "infofollowing" ||
                    // objects.apartmentStatus.length === "apartmentsold"
                    "0px"
                  : isSmallMobile === true
                  ? "20px"
                  : "100px",
            }}
          >
            {objects.apartmentStatus === "infofollowing" ? (
              <React.Fragment>
                {isSmallMobile === true ? (
                  <React.Fragment />
                ) : (
                  <div className="col-1" />
                )}
                <div className="col">
                  <p>
                    <i>Weitere Informationen folgen!</i>
                  </p>
                </div>
                {isSmallMobile === true ? (
                  <React.Fragment />
                ) : (
                  <div className="col-1" />
                )}
              </React.Fragment>
            ) : objects.apartmentStatus === "apartmentsold" ? (
              <React.Fragment>
                {isSmallMobile === true ? (
                  <React.Fragment />
                ) : (
                  <div className="col-1" />
                )}
                <div className="col">
                  <p>
                    <i>Alle Wohnungen verkauft!</i>
                  </p>
                </div>
                {isSmallMobile === true ? (
                  <React.Fragment />
                ) : (
                  <div className="col-1" />
                )}
              </React.Fragment>
            ) : (
              <React.Fragment />
            )}
          </div>
        </div>
        {objects.apartmentStatus === "apartmentavailable" ? (
          <React.Fragment />
        ) : (
          <div
            className={isMobile === true ? "col-12" : "col-6"}
            style={{
              paddingTop: "50px",
              paddingBottom: "50px",
              paddingRight:
                isSmallMobile === true
                  ? "20px"
                  : isMediumMobile === true
                  ? "60px"
                  : "80px",
              paddingLeft:
                isSmallMobile === true
                  ? "20px"
                  : isMediumMobile === true
                  ? "60px"
                  : "80px",
            }}
          >
            <picture>
              <source
                srcset="/assets/images/objectsblueprint.webp"
                type="image/webp"
                height="100%"
                width="100%"
              />
              <img
                src="/assets/images/objectsblueprint.jpg"
                alt="Objekt Example Blueprint"
                height="100%"
                width="100%"
              />
            </picture>
          </div>
        )}
      </div>
      <div className="row">
        <div className="col-1" />
        <div className="col-10">
          {objects.apartmentStatus === "apartmentavailable" ? (
            <div>
              <Tabs>
                <TabList style={{ textAlign: "center" }}>{tabInfo}</TabList>
                {tabContent}
              </Tabs>
            </div>
          ) : (
            <React.Fragment />
          )}
        </div>
        <div className="col-1" />
      </div>
    </div>
  );
}

function GoogleMapsPreview({ objects }) {
  const overlayId = "overlayId - " + objects.id;
  const wrapperId = "wrapperId - " + objects.id;

  const iframSettings = new IframeSettings("100%", "450", "0", objects.mapsUrl);
  const gdprConfig = new GDPRCoOvConfig(
    wrapperId,
    overlayId,
    30,
    iframSettings
  );
  const gdprCoOv = new GDPRCoOv(gdprConfig);

  React.useEffect(() => {
    gdprCoOv.init();
  });

  let useCookie = false;

  const checkboxHandler = (e) => {
    useCookie = e.target.checked;
  };

  const userAccepted = () => gdprCoOv.userAccepted(useCookie);

  return (
    <div
      className="container-fluid max-width: 100%"
      id="googlemapspreview"
      style={{
        justifyContent: "center",
        alignSelf: "center",
        backgroundColor: "#ffffff",
      }}
    >
      <div className="row" style={{ paddingTop: "50px" }}>
        <div className="col-1" />
        <div className="col">
          <h1>BS-Wohnbau Ingolstadt - {objects.name}</h1>
        </div>
        <div className="col-1" />
      </div>
      <div
        className="row"
        style={{
          paddingTop: "50px",
        }}
      >
        <div className="col-1" />
        <div className="col">
          <div id={wrapperId}>
            <div id={overlayId}>
              <div
                className="col"
                style={{ width: "100%", border: "solid 1px" }}
              >
                <div className="container" style={{ padding: "25px" }}>
                  <div className="row">
                    <p>
                      Zum Aktivieren der eingebetteten Karte bitte auf den Link
                      klicken. Durch das Aktivieren werden Daten an den
                      jeweiligen Anbieter übermittelt. Weitere Informationen
                      können unserer Datenschutzerklärung entnommen werden.
                    </p>
                  </div>
                  <div
                    className="row"
                    style={{
                      padding: "25px",
                      justifyContent: "center",
                      verticalAlign: "center",
                      alignSelf: "center",
                    }}
                  >
                    <button type="button" onClick={userAccepted}>
                      Inhalt anzeigen
                    </button>
                  </div>
                  <div
                    className="row"
                    style={{
                      justifyContent: "center",
                      verticalAlign: "center",
                      alignSelf: "center",
                    }}
                  >
                    <input
                      style={{
                        height: "22px",
                      }}
                      type="checkbox"
                      onChange={checkboxHandler}
                    />
                    <p
                      style={{
                        height: "20px",
                        textAlign: "center",
                        justifyContent: "center",
                        verticalAlign: "center",
                        alignSelf: "center",
                        paddingLeft: "5px",
                      }}
                    >
                      Auswahl merken
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <iframe
            title={"Objects Info Page - " + objects.address}
            src={objects.mapsUrl}
            // data-src={objects.mapsUrl}
            // data-2click-type="map"
            height="450px"
            width="100%"
            style={{ border: "none" }}
            allowfullscreen=""
            loading="lazy"
          /> */}
        </div>
        <div className="col-1" />
      </div>
    </div>
  );
}

class ObjectsInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div
        className="container-fluid max-width: 100%"
        style={{
          paddingLeft: "0px",
          paddingRight: "0px",
          overflow: "hidden",
        }}
      >
        <MetaDecorator
          title="Objektdetail - BS-Wohnbau Ingolstadt GmbH"
          description={this.props.objects.description}
          imageUrl={metaThumbnail}
          imageAlt="Bacher Icon Complete"
        />
        <ObjectsPreview objects={this.props.objects} />
        <ProjectPreview objects={this.props.objects} />
        <ObjectOutdoorCarouselPreview objects={this.props.objects} />
        <ContactPreview objects={this.props.objects} />
        <ObjectIndoorCarouselPreview objects={this.props.objects} />
        <ApartmentOverviewPreview objects={this.props.objects} />
        <GoogleMapsPreview objects={this.props.objects} />
      </div>
    );
  }
}

export default ObjectsInfo;
