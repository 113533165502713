import React, { Component } from "react";
// import { useState, useEffect } from "react";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import useWindowDimensions from "./WindowComponent";
import { Carousel } from "react-responsive-carousel";
import BackgroundLandingBanner from "../images/homepagebanner.jpg";
import BackgroundTeamLeaderBanner from "../images/bachericonred.jpg";
import BackgroundObjectsBanner from "../images/homeobjectsbanner.jpg";
// import BackgroundContactsBanner from "../images/homecontactsbanner.jpg";
import TeamLeaderBanner from "../images/hometeambanner.jpg";
import MetaDecorator from "./MetaDecorator";
import metaThumbnail from "../images/bachericonmetathumbnail.jpg";

function LandingBannerPreview(props) {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  // const [anchorTarget, setAnchorTarget] = useState(null);

  // useEffect(() => {
  //   setAnchorTarget(document.getElementById("learnaboutobjects"));
  // }, []);

  // const handleClick = (event) => {
  //   event.preventDefault();
  //   anchorTarget.scrollIntoView({
  //     behavior: "smooth",
  //     // block: "start",
  //     block: "center",
  //   });
  // };

  return (
    <div
      className="container-fluid max-width: 100%"
      style={{
        backgroundImage: "url(" + BackgroundLandingBanner + ")",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: 700,
        justifyContent: "center",
        verticalAlign: "center",
        alignSelf: "center",
        padding: "0px",
      }}
    >
      <div
        className="col"
        style={{
          position: "relative",
          top: "0px",
          left: "0px",
          height: "100%",
          width: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.6)",
          margin: "0px",
        }}
      >
        <div
          className="row"
          style={{
            height: 700,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            verticalAlign: "center",
            padding: "35px 0",
          }}
        >
          <div className="col">
            <div className="row">
              <div className="col">
                <h1
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontSize: isMobile === true ? "42px" : "54px",
                    textAlign: "center",
                    fontFamily: "Avenir",
                  }}
                >
                  Mieten statt Kaufen
                </h1>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <h1
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontSize: isMobile === true ? "42px" : "54px",
                    textAlign: "center",
                    fontFamily: "Avenir",
                  }}
                >
                  mit
                </h1>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <h1
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontSize: isMobile === true ? "42px" : "54px",
                    textAlign: "center",
                    fontFamily: "Avenir",
                  }}
                >
                  BS-Wohnbau Ingolstadt
                </h1>
              </div>
            </div>
            <div className="row row-content-small">
              <div className="col">
                <h5
                  style={{
                    color: "white",
                    textAlign: "center",
                    fontFamily: "Avenir",
                  }}
                >
                  Wohnräume im urbanen Kontext zu erschwinglichen Preisen.
                </h5>
              </div>
            </div>
            <div
              className="row"
              style={{
                justifyContent: "center",
                alignItems: "center",
                marginTop: isMobile === true ? "10px" : "10px",
                marginBottom: "40px",
              }}
            >
              <div
                className="col"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Link
                  to="/objects/fraunhofer-str"
                  style={{
                    color: "white",
                    fontFamily: "Avenir",
                    marginTop: "20px",
                  }}
                >
                  <Button
                    style={{
                      backgroundColor: "#C83743",
                      borderColor: "#C83743",
                      height: "48px",
                      width: "200px",
                    }}
                    size="lg"
                  >
                    Objekt entdecken
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function ObjectsPreview(props) {
  const isMobile = useMediaQuery({ query: "(max-width: 685px)" });

  const { width } = useWindowDimensions();

  return isMobile === true ? (
    <div
      className="container-fluid max-width: 100%"
      id="learnaboutobjects"
      style={{
        // backgroundImage: "url(" + BackgroundObjectsBanner + ")",
        // backgroundPosition: "left",
        // backgroundSize: "cover",
        // backgroundRepeat: "no-repeat",
        // height: 700,
        padding: "0px",
      }}
    >
      <div className="row">
        <div
          className="col-12"
          style={{
            // height: width / 2,
            backgroundColor: "#E4E5E6",
          }}
        >
          <div
            className="row"
            style={{
              // height: width / 2,
              justifyContent: "center",
              verticalAlign: "center",
              display: "flex",
              alignItems: "center",
              alignSelf: "center",
              padding: "50px",
            }}
          >
            <div className="col">
              <div
                className="row"
                style={{
                  justifyContent: "center",
                  verticalAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  alignSelf: "center",
                }}
              >
                <h1
                  style={{
                    color: "black",
                    fontWeight: "bold",
                    fontFamily: "Avenir",
                    textAlign: "center",
                    padding: "0px",
                  }}
                >
                  Unser Projekt.
                  <br />
                  Ihr neues Zuhause.
                </h1>
              </div>
              <div
                className="row"
                style={{
                  justifyContent: "center",
                  verticalAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  alignSelf: "center",
                }}
              >
                <Link
                  to="/objects"
                  style={{
                    color: "white",
                    fontFamily: "Avenir",
                    marginTop: "20px",
                  }}
                >
                  <Button
                    style={{
                      backgroundColor: "#C83743",
                      borderColor: "#C83743",
                      height: "48px",
                      width: "200px",
                    }}
                    size="lg"
                  >
                    Objekte erkunden
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <picture>
          <source
            srcset="/assets/images/gambrinusoutdoortwo.webp"
            type="image/webp"
            height="100%"
            width="100%"
          />
          <img
            src="/assets/images/gambrinusoutdoortwo.jpg"
            height="100%"
            width="100%"
            alt="Gambrinus Outdoor View"
          />
        </picture>
      </div>
    </div>
  ) : (
    <div
      className="container-fluid max-width: 100%"
      id="learnaboutobjects"
      style={{
        backgroundImage: "url(" + BackgroundObjectsBanner + ")",
        backgroundPosition: "left",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        // height: 700,
        padding: "0px",
      }}
    >
      <div className="row">
        <div
          className="col-6"
          style={{
            height: width / 2,
            backgroundColor: "#E4E5E6",
          }}
        >
          <div
            className="row"
            style={{
              height: width / 2,
              justifyContent: "center",
              verticalAlign: "center",
              display: "flex",
              alignItems: "center",
              alignSelf: "center",
              padding: "50px",
            }}
          >
            <div className="col">
              <div className="row">
                <h1
                  style={{
                    color: "black",
                    fontWeight: "bold",
                    fontFamily: "Avenir",
                    textAlign: "left",
                    padding: "0px",
                  }}
                >
                  Unser Projekt.
                </h1>
              </div>
              <div className="row">
                <h1
                  style={{
                    color: "black",
                    fontWeight: "bold",
                    fontFamily: "Avenir",
                    textAlign: "left",
                    marginTop: "20px",
                  }}
                >
                  Ihr neues Zuhause.
                </h1>
              </div>
              <div className="row">
                <Link
                  to="/objects"
                  style={{
                    color: "white",
                    fontFamily: "Avenir",
                    marginTop: "40px",
                  }}
                >
                  <Button
                    style={{
                      backgroundColor: "#C83743",
                      borderColor: "#C83743",
                      height: "48px",
                      width: "200px",
                    }}
                    size="lg"
                  >
                    Objekte erkunden
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="col-6" />
      </div>
    </div>
  );
}

function TeamLeaderPreview(props) {
  const isMobile = useMediaQuery({ query: "(max-width: 685px)" });
  const isTablet = useMediaQuery({ query: "(max-width: 852px)" });
  const { width } = useWindowDimensions();

  return isMobile === true ? (
    <div
      className="container-fluid max-width: 100%"
      style={{
        justifyContent: "center",
        alignSelf: "center",
        backgroundColor: "#E4E5E6",
      }}
    >
      <div className="row" style={{ padding: "50px" }}>
        <div
          className="col-12"
          style={{
            justifyContent: "center",
          }}
        >
          <div
            className="row"
            style={{
              padding:
                isMobile === true
                  ? "50px"
                  : isTablet === true
                  ? "20px"
                  : "50px",
              paddingTop: "0px",
              backgroundColor: "#E4E5E6",
              justifyContent: "center",
              verticalAlign: "center",
              display: "flex",
              alignItems: "center",
              alignSelf: "center",
            }}
          >
            <div className="col">
              <div
                className="row"
                style={{
                  justifyContent: "center",
                  verticalAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  alignSelf: "center",
                }}
              >
                <h1
                  style={{
                    fontWeight: "bold",
                    marginBottom: "20px",
                    textAlign: "center",
                  }}
                >
                  Wir. Die BS-Wohnbau Ingolstadt.
                </h1>
              </div>
              <div
                className="row"
                style={{
                  justifyContent: "center",
                  verticalAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  alignSelf: "center",
                }}
              >
                <h4
                  style={{
                    fontWeight: "bold",
                    marginTop: "20px",
                    textAlign: "center",
                  }}
                >
                  Zuverlässig. Seriös. Fair.
                </h4>
              </div>
            </div>
          </div>
          <div
            className="row"
            style={{
              justifyContent: "center",
              verticalAlign: "center",
              display: "flex",
              alignItems: "center",
              alignSelf: "center",
              textAlign: "center",
            }}
          >
            <div className="col">
              {isTablet === true ? (
                <React.Fragment>
                  <h5>
                    Schmidt Arno, <i>Maurermeister</i>
                  </h5>
                  <h5>
                    Bacher Martin, <i>Dipl. Ing. (FH)</i>
                  </h5>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <h4>
                    Schmidt Arno, <i>Maurermeister</i>
                  </h4>
                  <h4>
                    Bacher Martin, <i>Dipl. Ing. (FH)</i>
                  </h4>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row" style={{ height: "300px" }}>
        <div
          className="col"
          style={{
            backgroundImage: "url(" + BackgroundTeamLeaderBanner + ")",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            height: 300,
          }}
        ></div>
      </div>
    </div>
  ) : (
    <div
      className="container-fluid max-width: 100%"
      style={{
        height: 700,
        justifyContent: "center",
        alignSelf: "center",
        backgroundColor: "#E4E5E6",
      }}
    >
      <div className="row">
        <div
          className="col-7"
          style={{
            backgroundImage: "url(" + TeamLeaderBanner + ")",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            height: 700,
          }}
        />
        <div
          className="col-5"
          style={{
            height: 700,
            justifyContent: "center",
          }}
        >
          <div
            className="row"
            style={{
              height: "75px",
            }}
          />
          <div
            className="row"
            style={{
              position: "relative",
              top: "0px",
              right: isTablet === true ? "50px" : "100px",
              height: "400px",
              width:
                isTablet === true ? width * (5 / 12) : width * (5 / 12) + 100,
              padding: isTablet === true ? "20px" : "50px",
              backgroundColor: "#E4E5E6",
              justifyContent: "center",
              verticalAlign: "center",
              display: "flex",
              alignItems: "center",
              alignSelf: "center",
            }}
          >
            <div className="col">
              <div className="row">
                <h1
                  style={{
                    fontWeight: "bold",
                    marginBottom: "20px",
                  }}
                >
                  Wir. Die BS-Wohnbau Ingolstadt.
                </h1>
              </div>
              <div className="row">
                <h4
                  style={{
                    fontWeight: "bold",
                    marginTop: "20px",
                  }}
                >
                  Zuverlässig. Seriös. Fair.
                </h4>
              </div>
            </div>
          </div>
          <div
            className="row"
            style={{
              height: "200px",
            }}
          >
            <div
              className="col"
              style={{
                height: "225px",
                padding: "70px",
              }}
            >
              {isTablet === true ? (
                <React.Fragment>
                  <h5>
                    Schmidt Arno, <i>Maurermeister</i>
                  </h5>
                  <h5>
                    Bacher Martin, <i>Dipl. Ing. (FH)</i>
                  </h5>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <h4>
                    Schmidt Arno, <i>Maurermeister</i>
                  </h4>
                  <h4>
                    Bacher Martin, <i>Dipl. Ing. (FH)</i>
                  </h4>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function VisionPreview(props) {
  const isMobile = useMediaQuery({ query: "(max-width: 685px)" });

  return isMobile === true ? (
    <div
      className="container-fluid max-width: 100%"
      style={{
        // height: 350,
        justifyContent: "center",
        alignSelf: "center",
        backgroundColor: "#ffffff",
      }}
    >
      <div className="row">
        <div
          className="col"
          style={{
            padding: "25px",
            marginTop: "100px",
            marginBottom: "50px",
            marginLeft: "50px",
            marginRight: "50px",
            justifyContent: "center",
            backgroundColor: "#E4E5E6",
          }}
        >
          <h3>Unsere Motivation.</h3>
          <p>
            "Bauen aus Leidenschaft" ist unsere Devise. Die Begeisterung für das
            Bauen treibt uns täglich an das Beste zu geben.
          </p>
        </div>
      </div>
      <div className="row">
        <div
          className="col"
          style={{
            padding: "25px",
            marginTop: "0px",
            marginBottom: "100px",
            marginLeft: "50px",
            marginRight: "50px",
            justifyContent: "center",
            backgroundColor: "#E4E5E6",
          }}
        >
          <h3>Unsere Vision.</h3>
          <p>
            Wir schaffen qualitativ hochwertige Wohnungen im urbanen Kontext zu
            erschwinglichen Preisen. Dies erreichen wir mit Zuverlässigkeit,
            Seriosität und Fairness.
          </p>
        </div>
      </div>
    </div>
  ) : (
    <div
      className="container-fluid max-width: 100%"
      style={{
        // height: 350,
        justifyContent: "center",
        alignSelf: "center",
        backgroundColor: "#ffffff",
      }}
    >
      <div className="row">
        <div
          className="col"
          style={{
            padding: "25px",
            marginTop: "100px",
            marginBottom: "100px",
            marginLeft: "100px",
            marginRight: "20px",
            justifyContent: "center",
            backgroundColor: "#E4E5E6",
          }}
        >
          <h3>Unsere Motivation.</h3>
          <p>
            "Bauen aus Leidenschaft" ist unsere Devise. Die Begeisterung für das
            Bauen treibt uns täglich an das Beste zu geben.
          </p>
        </div>
        <div
          className="col"
          style={{
            padding: "25px",
            marginTop: "100px",
            marginBottom: "100px",
            marginLeft: "20px",
            marginRight: "100px",
            justifyContent: "center",
            backgroundColor: "#E4E5E6",
          }}
        >
          <h3>Unsere Vision.</h3>
          <p>
            Wir schaffen qualitativ hochwertige Wohnungen im urbanen Kontext zu
            erschwinglichen Preisen. Dies erreichen wir mit Zuverlässigkeit,
            Seriosität und Fairness.
          </p>
        </div>
      </div>
    </div>
  );
}

function PurchaseContactPreview(props) {
  const isMobile = useMediaQuery({ query: "(max-width: 685px)" });

  const { width } = useWindowDimensions();

  return isMobile === true ? (
    <div
      className="container-fluid max-width: 100%"
      style={{
        justifyContent: "center",
        verticalAlign: "center",
        alignSelf: "center",
        padding: "0px",
      }}
    >
      <div
        className="row"
        style={{
          padding: "0px",
          margin: "0px",
        }}
      >
        <div
          className="col-12"
          style={{
            // height: width / 2,
            display: "flex",
            backgroundColor: "#E4E5E6",
            padding: "50px",
          }}
        >
          <div
            className="col"
            style={{
              alignItems: "center",
              alignSelf: "center",
            }}
          >
            <div
              className="row"
              style={{
                justifyContent: "center",
                verticalAlign: "center",
                display: "flex",
                alignItems: "center",
                alignSelf: "center",
              }}
            >
              <h1
                style={{
                  color: "black",
                  fontWeight: "bold",
                  fontFamily: "Avenir",
                  marginTop: "40px",
                  textAlign: "center",
                }}
              >
                Wir kaufen Grundstücke.
              </h1>
            </div>
            <div className="row">
              <p
                style={{
                  color: "black",
                  fontFamily: "Avenir",
                  textAlign: "center",
                }}
              >
                Sind Sie auf der Suche nach einem Käufer für Ihr Grundstück?
                Dann kontaktieren Sie uns!
              </p>
            </div>
            <div className="row">
              <div
                className="col"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "0px",
                  marginTop: "20px",
                }}
              >
                <Link
                  to="/contact"
                  style={{ color: "white", fontFamily: "Avenir" }}
                >
                  <Button
                    style={{
                      backgroundColor: "#C83743",
                      borderColor: "#C83743",
                      height: "48px",
                      width: "200px",
                    }}
                    size="lg"
                  >
                    Kontakt
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-12"
          style={{
            padding: "0px",
          }}
        >
          <picture>
            <source
              srcset="/assets/images/greengrass.webp"
              type="image/webp"
              height="100%"
              width="100%"
            />
            <img
              src="/assets/images/greengrass.jpg"
              height="100%"
              width="100%"
              alt="Green grass"
            />
          </picture>
        </div>
      </div>
    </div>
  ) : (
    <div
      className="container-fluid max-width: 100%"
      style={{
        justifyContent: "center",
        verticalAlign: "center",
        alignSelf: "center",
        padding: "0px",
      }}
    >
      <div
        className="row"
        style={{
          padding: "0px",
          margin: "0px",
        }}
      >
        <div
          className="col-6"
          style={{
            height: width / 2,
            display: "flex",
            backgroundColor: "#E4E5E6",
            padding: "50px",
          }}
        >
          <div
            className="col"
            style={{
              alignItems: "center",
              alignSelf: "center",
            }}
          >
            <div className="row">
              <h1
                style={{
                  color: "black",
                  fontWeight: "bold",
                  fontFamily: "Avenir",
                  textAlign: "left",
                }}
              >
                Wir kaufen Grundstücke.
              </h1>
            </div>
            <div className="row">
              <p
                style={{
                  color: "black",
                  fontFamily: "Avenir",
                  textAlign: "left",
                }}
              >
                Sind Sie auf der Suche nach einem Käufer für Ihr Grundstück?
                Dann kontaktieren Sie uns!
              </p>
            </div>
            <div className="row">
              <div
                className="col"
                style={{
                  // display: "flex",
                  // justifyContent: "center",
                  // alignItems: "center",
                  padding: "0px",
                  marginTop: "20px",
                }}
              >
                <Link
                  to="/contact"
                  style={{ color: "white", fontFamily: "Avenir" }}
                >
                  <Button
                    style={{
                      backgroundColor: "#C83743",
                      borderColor: "#C83743",
                      height: "48px",
                      width: "200px",
                    }}
                    size="lg"
                  >
                    Kontakt
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-6"
          style={{
            padding: "0px",
          }}
        >
          <picture>
            <source
              srcset="/assets/images/greengrass.webp"
              type="image/webp"
              height="100%"
              width="100%"
            />
            <img
              src="/assets/images/greengrass.jpg"
              height="100%"
              width="100%"
              alt="Green grass"
            />
          </picture>
        </div>
      </div>
    </div>
  );
}

function ReferencesPreview({ references }) {
  const isTablet = useMediaQuery({ query: "(max-width: 900px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const isSmallMobile = useMediaQuery({ query: "(max-width: 390px)" });

  const carouselItem = references.map((reference) => {
    return (
      <div
        className="container-fluid max-width: 100%"
        style={{
          height: 600,
          width: "100%",
          padding: "0px",
          backgroundImage:
            isTablet === true
              ? "url(" + reference.imageSmall + ")"
              : "url(" + reference.image + ")",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div
          className="col-12"
          style={{
            position: "relative",
            top: "0px",
            left: "0px",
            height: "600px",
            width: "100%",
            margin: "0px",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
          }}
        >
          <div
            className="row"
            style={{
              height: 600,
              justifyContent: "center",
              verticalAlign: "center",
              display: "flex",
              alignItems: "center",
              alignSelf: "center",
              paddingLeft: "20px",
              paddingRight: "20px",
            }}
          >
            {isSmallMobile === true ? (
              <React.Fragment />
            ) : isMobile === true ? (
              <div className="col-1" />
            ) : (
              <div className="col-2" />
            )}
            <div
              className="col"
              style={{
                border: "3px solid #000000",
                paddingLeft: "75px",
                paddingRight: "75px",
                paddingTop: "75px",
                paddingBottom: "75px",
              }}
            >
              <p
                style={{
                  color: "black",
                  marginTop: "auto",
                  marginBottom: "auto",
                }}
              >
                "{reference.title}"
              </p>
            </div>
            {isSmallMobile === true ? (
              <React.Fragment />
            ) : isMobile === true ? (
              <div className="col-1" />
            ) : (
              <div className="col-2" />
            )}
          </div>
        </div>
      </div>
      // <img height="600px" src={reference.image} />
    );
  });

  return (
    <div
      className="container-fluid max-width: 100%"
      style={{
        justifyContent: "center",
        alignSelf: "center",
        backgroundColor: "#ffffff",
      }}
    >
      <div className="row">
        <div
          className="col"
          style={{
            padding: "75px",
            justifyContent: "center",
            alignSelf: "center",
          }}
        >
          <h1 style={{ textAlign: "center" }}>
            Unsere Kunden? Die Beste Referenz!
          </h1>
        </div>
      </div>
      <div className="row">
        <div className="col-12" style={{ padding: "0px" }}>
          <Carousel
            autoPlay={true}
            infiniteLoop={true}
            interval="5000"
            showStatus={false}
            showThumbs={false}
          >
            {carouselItem}
          </Carousel>
        </div>
      </div>
    </div>
  );
}

function ContactsPreview(props) {
  const isMobile = useMediaQuery({ query: "(max-width: 685px)" });

  return isMobile === true ? (
    <div
      className="container-fluid max-width: 100%"
      style={{
        justifyContent: "center",
        alignSelf: "center",
        backgroundColor: "#000000",
      }}
    >
      <div className="row">
        <div
          className="col-12"
          style={{
            paddingLeft: "50px",
            paddingRight: "50px",
            paddingTop: "50px",
            paddingBottom: "25px",
            // padding: "75px",
            justifyContent: "center",
            alignSelf: "center",
          }}
        >
          <row>
            <h1
              style={{
                color: "#ffffff",
                paddingBottom: "40px",
                textAlign: "center",
              }}
            >
              Ihr Weg zu uns.
            </h1>
          </row>
          <row
            style={{
              justifyContent: "center",
              verticalAlign: "center",
              display: "flex",
              alignItems: "center",
              alignSelf: "center",
            }}
          >
            <Link
              to="/contact"
              style={{ color: "white", fontFamily: "Avenir" }}
            >
              <Button
                style={{
                  backgroundColor: "#C83743",
                  borderColor: "#C83743",
                  height: "48px",
                  width: "200px",
                }}
                size="lg"
              >
                Kontakt
              </Button>
            </Link>
          </row>
        </div>
      </div>
      <div className="row">
        <div
          className="col-12"
          style={{
            // paddingTop: "50px",
            // paddingBottom: "50px",
            // paddingLeft: "50px",
            // paddingRight: "50px",
            justifyContent: "center",
            alignSelf: "center",
          }}
        >
          <picture>
            <source
              srcset="/assets/images/bachericonblackcomplete.webp"
              type="image/webp"
              height="100%"
              width="100%"
            />
            <img
              src="/assets/images/bachericonblackcomplete.jpg"
              height="100%"
              width="100%"
              alt="Bacher Icon Complete"
            />
          </picture>
        </div>
      </div>
    </div>
  ) : (
    <div
      className="container-fluid max-width: 100%"
      style={{
        justifyContent: "center",
        alignSelf: "center",
        backgroundColor: "#000000",
      }}
    >
      <div className="row">
        <div
          className="col-6"
          style={{
            paddingTop: "50px",
            paddingBottom: "50px",
            paddingLeft: "75px",
            paddingRight: "10px",
            justifyContent: "center",
            alignSelf: "center",
          }}
        >
          <picture>
            <source
              srcset="/assets/images/bachericonblackcomplete.webp"
              type="image/webp"
              height="100%"
              width="100%"
            />
            <img
              src="/assets/images/bachericonblackcomplete.jpg"
              height="100%"
              width="100%"
              alt="Bacher Icon Complete"
            />
          </picture>
        </div>
        <div
          className="col-6"
          style={{
            padding: "75px",
            justifyContent: "center",
            alignSelf: "center",
          }}
        >
          <row>
            <h1 style={{ color: "#ffffff", paddingBottom: "40px" }}>
              Ihr Weg zu uns.
            </h1>
          </row>
          <row>
            <Link
              to="/contact"
              style={{ color: "white", fontFamily: "Avenir" }}
            >
              <Button
                style={{
                  backgroundColor: "#C83743",
                  borderColor: "#C83743",
                  height: "48px",
                  width: "200px",
                }}
                size="lg"
              >
                Kontakt
              </Button>
            </Link>
          </row>
        </div>
      </div>
    </div>
  );
}

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div
        className="container-fluid max-width: 100%"
        style={{
          paddingLeft: "0px",
          paddingRight: "0px",
          overflow: "hidden",
        }}
      >
        <MetaDecorator
          title="Home - BS-Wohnbau Ingolstadt GmbH"
          description="Die BS-Wohnbau Ingolstadt GmbH schafft qualitativ hochwertige Wohnräume im urbanen Kontext zu erschwinglichen Preisen."
          imageUrl={metaThumbnail}
          imageAlt="Bacher Icon Complete"
        />
        <LandingBannerPreview />
        <ObjectsPreview />
        <TeamLeaderPreview />
        <VisionPreview />
        <PurchaseContactPreview />
        <ReferencesPreview references={this.props.references} />
        <ContactsPreview />
      </div>
    );
  }
}

export default Home;
