// import React from "react";
import { useState, useEffect } from "react";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

// export function showCloseAlert() {
//   const [alert, setAlert] = React.useState({
//     type: "error",
//     text: "This is a alert message",
//     show: false,
//   });

//   function onCloseAlert() {
//     setAlert({
//       type: "",
//       text: "",
//       show: false,
//     });
//   }

//   function onShowAlert(type) {
//     setAlert({
//       type: type,
//       text: "Demo alert",
//       show: true,
//     });
//   }

//   return alert;
// }
