import React, { Component } from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
  FormFeedback,
} from "reactstrap";
import { Link } from "react-router-dom";
import emailjs from "emailjs-com";
// import { init } from "emailjs-com";
import { useMediaQuery } from "react-responsive";
import BackgroundRiverBanner from "../images/contactriverbanner.jpg";
import MetaDecorator from "./MetaDecorator";
import metaThumbnail from "../images/bachericonmetathumbnail.jpg";
import { GDPRCoOv, GDPRCoOvConfig, IframeSettings } from "gdpr-c-o";

function LandingBannerPreview(props) {
  return (
    <div
      className="container-fluid max-width: 100%"
      style={{
        // backgroundImage: "url(" + BackgroundLandingBanner + ")",
        // backgroundPosition: "center",
        // backgroundSize: "cover",
        // backgroundRepeat: "no-repeat",
        backgroundColor: "black",
        // height: 600,
        width: "100%",
        justifyContent: "center",
        verticalAlign: "center",
        alignSelf: "center",
        padding: "0px",
      }}
    >
      <div className="row">
        <div className="col">
          <picture>
            <source
              srcset="/assets/images/bachericonblackcomplete.webp"
              type="image/webp"
            />
            <img
              src="/assets/images/bachericonblackcomplete.jpg"
              height="100%"
              width="100%"
              alt="Bacher Icon Full Black"
            />
          </picture>
        </div>
      </div>
    </div>
  );
}

function ContactPreview(props) {
  const isMobile = useMediaQuery({ query: "(max-width: 685px)" });
  const isSmallMobile = useMediaQuery({ query: "(max-width: 430px)" });

  return isMobile === true ? (
    <div
      className="container-fluid max-width: 100%"
      style={{
        justifyContent: "center",
        verticalAlign: "center",
        alignSelf: "center",
        padding: "0px",
      }}
    >
      <div className="row" style={{ padding: "0px" }}>
        {isSmallMobile === true ? (
          <React.Fragment />
        ) : (
          <div className="col-1" />
        )}
        <div
          className={isSmallMobile === true ? "col-12" : "col-10"}
          style={{
            // backgroundColor: "#E4E5E6",
            padding: "50px",
          }}
        >
          <h1
            style={{
              color: "black",
              fontWeight: "bold",
              fontFamily: "Avenir",
              textAlign: "left",
              marginTop: "20px",
            }}
          >
            Kontakt.
          </h1>
        </div>
        {isSmallMobile === true ? (
          <React.Fragment />
        ) : (
          <div className="col-1" />
        )}
      </div>
      <div className="row" style={{ padding: "0px" }}>
        {isSmallMobile === true ? (
          <React.Fragment />
        ) : (
          <div className="col-1" />
        )}
        <div
          className={isSmallMobile === true ? "col-12" : "col-10"}
          style={{
            paddingLeft: "50px",
            paddingRight: "50px",
            paddingBottom: "50px",
          }}
        >
          <h3
            style={{
              color: "black",
              fontWeight: "bold",
              fontFamily: "Avenir",
              textAlign: "left",
              marginTop: "0px",
            }}
          >
            BS-Wohnbau Ingolstadt GmbH
          </h3>
          <p>
            Friedrich-Ebert-Straße 18 1/4
            <br />
            85055 Ingolstadt
          </p>
          <p>
            <a
              role="button"
              className="btn btn-link"
              href="mailto:info@bsw-in.de"
              style={{
                color: "black",
                paddingLeft: "0px",
                paddingBottom: "0px",
              }}
            >
              info@bsw-in.de
            </a>
            <br />
            <a
              role="button"
              className="btn btn-link"
              href="tel:0841955920"
              style={{ color: "black", paddingLeft: "0px", paddingTop: "0px" }}
            >
              0841 95592-0
            </a>
          </p>
        </div>
        {isSmallMobile === true ? (
          <React.Fragment />
        ) : (
          <div className="col-1" />
        )}
      </div>
    </div>
  ) : (
    <div
      className="container-fluid max-width: 100%"
      style={{
        justifyContent: "center",
        verticalAlign: "center",
        alignSelf: "center",
        padding: "0px",
      }}
    >
      <div className="row" style={{ padding: "0px" }}>
        <div className="col-1" />
        <div
          className="col-5"
          style={{
            // backgroundColor: "#E4E5E6",
            padding: "50px",
          }}
        >
          <h1
            style={{
              color: "black",
              fontWeight: "bold",
              fontFamily: "Avenir",
              textAlign: "left",
              marginTop: "40px",
            }}
          >
            Kontakt.
          </h1>
        </div>
        <div
          className="col-5"
          style={{
            padding: "50px",
          }}
        >
          <h3
            style={{
              color: "black",
              fontWeight: "bold",
              fontFamily: "Avenir",
              textAlign: "left",
              marginTop: "40px",
            }}
          >
            BS-Wohnbau Ingolstadt GmbH
          </h3>
          <p>
            Friedrich-Ebert-Straße 18 1/4
            <br />
            85055 Ingolstadt
          </p>
          <p>
            <a
              role="button"
              className="btn btn-link"
              href="mailto:info@bsw-in.de"
              style={{
                color: "black",
                paddingLeft: "0px",
                paddingBottom: "0px",
              }}
            >
              info@bsw-in.de
            </a>
            <br />
            <a
              role="button"
              className="btn btn-link"
              href="tel:0841955920"
              style={{ color: "black", paddingLeft: "0px", paddingTop: "0px" }}
            >
              0841 95592-0
            </a>
          </p>
        </div>
        <div className="col-1" />
      </div>
    </div>
  );
}

function RiverImagePreview(props) {
  return (
    <div
      className="container-fluid max-width: 100%"
      style={{
        backgroundImage: "url(" + BackgroundRiverBanner + ")",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: 300,
        // height: "100%",
        width: "100%",
        justifyContent: "center",
        verticalAlign: "center",
        alignSelf: "center",
        padding: "0px",
      }}
    >
      {/* <div className="row">
        <div className="col">
          <picture>
            <img
              src="assets/images/bachericonblackcomplete.jpg"
              height="100%"
              width="100%"
              alt="Bacher Icon Full Black"
            />
          </picture>
        </div>
      </div> */}
    </div>
  );
}

function GoogleMapsPreview({ height }) {
  const overlayId = "overlayId - contactpage";
  const wrapperId = "wrapperId - contactpage";

  const iframSettings = new IframeSettings(
    "100%",
    height,
    "0",
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2629.6480802181236!2d11.437104151119678!3d48.76951647917711!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479eff7d5d3bf1f1%3A0x44ef10e9356a3d75!2sBS-Wohnbau%20Ingolstadt%20GmbH!5e0!3m2!1sen!2sde!4v1634202544315!5m2!1sen!2sde"
  );
  const gdprConfig = new GDPRCoOvConfig(
    wrapperId,
    overlayId,
    30,
    iframSettings
  );
  const gdprCoOv = new GDPRCoOv(gdprConfig);

  React.useEffect(() => {
    gdprCoOv.init();
  });

  let useCookie = false;

  const checkboxHandler = (e) => {
    useCookie = e.target.checked;
  };

  const userAccepted = () => gdprCoOv.userAccepted(useCookie);

  return (
    <div id={wrapperId}>
      <div id={overlayId}>
        <div className="col" style={{ width: "100%", border: "solid 1px" }}>
          <div className="container" style={{ padding: "25px" }}>
            <div className="row">
              <p>
                Zum Aktivieren der eingebetteten Karte bitte auf den Link
                klicken. Durch das Aktivieren werden Daten an den jeweiligen
                Anbieter übermittelt. Weitere Informationen können unserer
                Datenschutzerklärung entnommen werden.
              </p>
            </div>
            <div
              className="row"
              style={{
                padding: "25px",
                justifyContent: "center",
                verticalAlign: "center",
                alignSelf: "center",
              }}
            >
              <button type="button" onClick={userAccepted}>
                Inhalt anzeigen
              </button>
            </div>
            <div
              className="row"
              style={{
                justifyContent: "center",
                verticalAlign: "center",
                alignSelf: "center",
              }}
            >
              <input
                style={{
                  height: "22px",
                }}
                type="checkbox"
                onChange={checkboxHandler}
              />
              <p
                style={{
                  height: "20px",
                  textAlign: "center",
                  justifyContent: "center",
                  verticalAlign: "center",
                  alignSelf: "center",
                  paddingLeft: "5px",
                }}
              >
                Auswahl merken
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

class ContactFormPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      requestAnswer: "",
      name: "",
      email: "",
      phoneNumber: "",
      message: "",
      touched: {
        email: false,
        message: false,
      },
      mapsPreview: <GoogleMapsPreview height="700" />,
      mapsPreviewMobile: <GoogleMapsPreview height="400" />,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleResize = (e) => {
    this.setState({ width: window.innerWidth });
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.addEventListener("resize", this.handleResize);
  }

  validate(phoneNumber, email, message) {
    const errors = {
      phoneNumber: "",
      email: "",
      message: "",
    };

    const reg = /^\d+$/;
    if (this.state.touched.phoneNumber && !reg.test(phoneNumber)) {
      errors.phoneNumber = "Die Telefonnumer darf nur Zahlen beinhalten.";
    }

    if (this.state.touched.email && email.length === 0) {
      errors.email = "Dieses Feld darf nicht leer sein.";
    } else if (this.state.touched.email && !email.includes("@")) {
      errors.email = "Die E-Mail-Adresse sollte ein @ beinhalten.";
    }

    if (this.state.touched.message && message.length === 0) {
      errors.message = "Dieses Feld darf nicht leer sein.";
    }

    return errors;
  }

  handleBlur = (field) => () => {
    this.setState({
      touched: { ...this.state.touched, [field]: true },
    });
  };

  handleInputChange(event) {
    const target = event.target;
    const name = target.name;
    const value = target.value;

    this.setState({
      [name]: value,
    });
  }

  handleSubmit(event) {
    // console.log("Current state is: " + JSON.stringify(this.state));
    if (this.state.email.length !== 0 && this.state.message.length !== 0) {
      // alert("Current state is: " + JSON.stringify(this.state));

      // init(process.env.REACT_APP_USER_ID);
      emailjs.init(process.env.REACT_APP_PUBLIC_KEY);
      const templateParams = {
        name: this.state.name,
        email: this.state.email,
        phone_number: this.state.phoneNumber,
        message: this.state.message,
      };
      emailjs
        .send(
          process.env.REACT_APP_SERVICE_ID,
          process.env.REACT_APP_CONTACT_TEMPLATE_ID,
          templateParams
          // process.env.REACT_APP_USER_ID
        )
        .then(
          (response) => {
            this.setState({
              requestAnswer: "success",
            });
            console.log("SUCCESS:", response.status, response.text);
            // alert("Message Sent, We will get back to you shortly", response.text);
          },
          (error) => {
            this.setState({
              requestAnswer: "error",
            });
            console.log("ERROR:", error);
            // alert("An error occurred, Please try again", error.text);
          }
        );
    }
    event.preventDefault();
  }

  render() {
    const isMobile = this.state.width <= 685 ? true : false;
    const isSmallMobile = this.state.width <= 430 ? true : false;

    const errors = this.validate(
      this.state.phoneNumber,
      this.state.email,
      this.state.message
    );

    const requestAnswer = this.state.requestAnswer;

    const googleMapsPreview = this.state.mapsPreview;
    const googleMapsPreviewMobile = this.state.mapsPreviewMobile;

    return isMobile === true ? (
      <div
        className="container-fluid max-width: 100%"
        style={{
          justifyContent: "center",
          verticalAlign: "center",
          alignSelf: "center",
          padding: "0px",
        }}
      >
        <div className="row" style={{ padding: "0px" }}>
          {isSmallMobile === true ? (
            <React.Fragment />
          ) : (
            <div className="col-1" />
          )}
          <div
            className={isSmallMobile === true ? "col-12" : "col-10"}
            style={{
              // backgroundColor: "#E4E5E6",
              padding: "50px",
            }}
          >
            <h1
              style={{
                color: "black",
                fontWeight: "bold",
                fontFamily: "Avenir",
                textAlign: "left",
                marginTop: "40px",
              }}
            >
              Kontaktieren Sie uns.
            </h1>
            <Form onSubmit={this.handleSubmit}>
              <FormGroup row style={{ paddingTop: "10px" }}>
                <Label htmlFor="name" md={12}>
                  Name
                </Label>
                <Col md={12}>
                  <Input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Name"
                    value={this.state.name}
                    onChange={this.handleInputChange}
                  />
                </Col>
              </FormGroup>
              <FormGroup row style={{ paddingTop: "10px" }}>
                <Label htmlFor="email" md={12}>
                  E-Mail-Adresse (Pflichtfeld)
                </Label>
                <Col md={12}>
                  <Input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="E-Mail-Adresse"
                    value={this.state.email}
                    invalid={errors.email}
                    onBlur={this.handleBlur("email")}
                    onChange={this.handleInputChange}
                  />
                  <FormFeedback>{errors.email}</FormFeedback>
                </Col>
              </FormGroup>
              <FormGroup row style={{ paddingTop: "10px" }}>
                <Label htmlFor="phoneNumber" md={12}>
                  Telefonnummer
                </Label>
                <Col md={12}>
                  <Input
                    type="tel"
                    id="phoneNumber"
                    name="phoneNumber"
                    placeholder="Telefonnummer"
                    value={this.state.phoneNumber}
                    invalid={errors.phoneNumber}
                    onChange={this.handleInputChange}
                  />
                  <FormFeedback>{errors.phoneNumber}</FormFeedback>
                </Col>
              </FormGroup>
              <FormGroup row style={{ paddingTop: "10px" }}>
                <Label htmlFor="message" md={12}>
                  Nachricht (Pflichtfeld)
                </Label>
                <Col md={12}>
                  <Input
                    type="textarea"
                    id="message"
                    name="message"
                    rows="4"
                    value={this.state.message}
                    invalid={errors.message}
                    onBlur={this.handleBlur("message")}
                    onChange={this.handleInputChange}
                  />
                  <FormFeedback>{errors.message}</FormFeedback>
                </Col>
              </FormGroup>
              <p style={{ paddingTop: "10px" }}>
                Es gilt unsere{" "}
                <Link to="/privacypolicy" style={{ color: "black" }}>
                  Datenschutzerklärung
                </Link>
                .
              </p>
              {requestAnswer === "success" ? (
                <p style={{ color: "green" }}>
                  Wir haben Ihre Anfrage erhalten und werden uns in Kürze bei
                  Ihnen melden!
                </p>
              ) : requestAnswer === "error" ? (
                <p style={{ color: "red" }}>
                  Leider ist ein Fehler aufgetreten! Bitte versuchen Sie es
                  erneut.
                </p>
              ) : (
                <React.Fragment />
              )}
              <FormGroup row style={{ paddingTop: "10px" }}>
                <Col>
                  <Button
                    type="submit"
                    style={{
                      backgroundColor: "#C83743",
                      borderColor: "#C83743",
                      height: "48px",
                      width: "200px",
                    }}
                    onSubmit={this.handleSubmit}
                  >
                    Absenden
                  </Button>
                </Col>
              </FormGroup>
            </Form>
          </div>
          {isSmallMobile === true ? (
            <React.Fragment />
          ) : (
            <div className="col-1" />
          )}
        </div>
        <div className="row" style={{ padding: "0px" }}>
          {isSmallMobile === true ? (
            <React.Fragment />
          ) : (
            <div className="col-1" />
          )}
          <div
            className={isSmallMobile === true ? "col-12" : "col-10"}
            style={{
              paddingTop: "0px",
              paddingBottom: "50px",
              paddingLeft: "50px",
              paddingRight: "50px",
            }}
          >
            {/* <GoogleMapsPreview height="400" /> */}
            {googleMapsPreviewMobile}
            {/* <iframe
              title="Contact Page - Friedrich-Ebert-Straße 18"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2629.6480802181236!2d11.437104151119678!3d48.76951647917711!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479eff7d5d3bf1f1%3A0x44ef10e9356a3d75!2sBS-Wohnbau%20Ingolstadt%20GmbH!5e0!3m2!1sen!2sde!4v1634202544315!5m2!1sen!2sde"
              height="400px"
              width="100%"
              style={{ border: "none" }}
              allowfullscreen=""
              loading="lazy"
            /> */}
          </div>
          {isSmallMobile === true ? (
            <React.Fragment />
          ) : (
            <div className="col-1" />
          )}
        </div>
      </div>
    ) : (
      <div
        className="container-fluid max-width: 100%"
        style={{
          justifyContent: "center",
          verticalAlign: "center",
          alignSelf: "center",
          padding: "0px",
        }}
      >
        <div className="row" style={{ padding: "0px" }}>
          <div className="col-1" />
          <div
            className="col-5"
            style={{
              // backgroundColor: "#E4E5E6",
              paddingTop: "50px",
              paddingBottom: "50px",
              paddingLeft: "50px",
              paddingRight: "25px",
            }}
          >
            <h1
              style={{
                color: "black",
                fontWeight: "bold",
                fontFamily: "Avenir",
                textAlign: "left",
                marginTop: "40px",
              }}
            >
              Kontaktieren Sie uns.
            </h1>
            <Form onSubmit={this.handleSubmit}>
              <FormGroup row style={{ paddingTop: "10px" }}>
                <Label htmlFor="name" md={12}>
                  Name
                </Label>
                <Col md={12}>
                  <Input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Name"
                    value={this.state.name}
                    onChange={this.handleInputChange}
                  />
                </Col>
              </FormGroup>
              <FormGroup row style={{ paddingTop: "10px" }}>
                <Label htmlFor="email" md={12}>
                  E-Mail-Adresse (Pflichtfeld)
                </Label>
                <Col md={12}>
                  <Input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="E-Mail-Adresse"
                    value={this.state.email}
                    invalid={errors.email}
                    onBlur={this.handleBlur("email")}
                    onChange={this.handleInputChange}
                  />
                  <FormFeedback>{errors.email}</FormFeedback>
                </Col>
              </FormGroup>
              <FormGroup row style={{ paddingTop: "10px" }}>
                <Label htmlFor="phoneNumber" md={12}>
                  Telefonnummer
                </Label>
                <Col md={12}>
                  <Input
                    type="tel"
                    id="phoneNumber"
                    name="phoneNumber"
                    placeholder="Telefonnummer"
                    value={this.state.phoneNumber}
                    invalid={errors.phoneNumber}
                    onChange={this.handleInputChange}
                  />
                  <FormFeedback>{errors.phoneNumber}</FormFeedback>
                </Col>
              </FormGroup>
              <FormGroup row style={{ paddingTop: "10px" }}>
                <Label htmlFor="message" md={12}>
                  Nachricht (Pflichtfeld)
                </Label>
                <Col md={12}>
                  <Input
                    type="textarea"
                    id="message"
                    name="message"
                    rows="4"
                    value={this.state.message}
                    invalid={errors.message}
                    onBlur={this.handleBlur("message")}
                    onChange={this.handleInputChange}
                  />
                  <FormFeedback>{errors.message}</FormFeedback>
                </Col>
              </FormGroup>
              <p style={{ paddingTop: "10px" }}>
                Es gilt unsere{" "}
                <Link to="/privacypolicy" style={{ color: "black" }}>
                  Datenschutzerklärung
                </Link>
                .
              </p>
              {requestAnswer === "success" ? (
                <p style={{ color: "green" }}>
                  Wir haben Ihre Anfrage erhalten und werden uns in Kürze bei
                  Ihnen melden!
                </p>
              ) : requestAnswer === "error" ? (
                <p style={{ color: "red" }}>
                  Leider ist ein Fehler aufgetreten! Bitte versuchen Sie es
                  erneut.
                </p>
              ) : (
                <React.Fragment />
              )}
              <FormGroup row style={{ paddingTop: "10px" }}>
                <Col>
                  <Button
                    type="submit"
                    style={{
                      backgroundColor: "#C83743",
                      borderColor: "#C83743",
                      height: "48px",
                      width: "200px",
                    }}
                    onSubmit={this.handleSubmit}
                  >
                    Absenden
                  </Button>
                </Col>
              </FormGroup>
            </Form>
          </div>
          <div
            className="col-5"
            style={{
              paddingTop: "50px",
              paddingBottom: "50px",
              paddingLeft: "25px",
              paddingRight: "50px",
              justifyContent: "center",
              verticalAlign: "center",
              alignSelf: "center",
            }}
          >
            {/* <GoogleMapsPreview height="700" /> */}
            {googleMapsPreview}
            {/* <iframe
              title="Contact Page - BS-Wohnbau Ingolstadt GmbH"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2629.6480802181236!2d11.437104151119678!3d48.76951647917711!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479eff7d5d3bf1f1%3A0x44ef10e9356a3d75!2sBS-Wohnbau%20Ingolstadt%20GmbH!5e0!3m2!1sen!2sde!4v1634202544315!5m2!1sen!2sde"
              height="100%"
              width="100%"
              style={{ border: "none" }}
              allowfullscreen=""
              loading="lazy"
            /> */}
          </div>
          <div className="col-1" />
        </div>
      </div>
    );
  }
}

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div
        className="container-fluid max-width: 100%"
        style={{
          paddingLeft: "0px",
          paddingRight: "0px",
          overflow: "hidden",
        }}
      >
        <MetaDecorator
          title="Kontakt - BS-Wohnbau Ingolstadt GmbH"
          description="Ihr Weg zu uns."
          imageUrl={metaThumbnail}
          imageAlt="Bacher Icon Complete"
        />
        <LandingBannerPreview />
        <ContactPreview />
        <RiverImagePreview />
        <ContactFormPreview />
      </div>
    );
  }
}

export default Contact;
