import React, { Component } from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
  FormFeedback,
} from "reactstrap";
import { Link } from "react-router-dom";
import emailjs from "emailjs-com";
// import { init } from "emailjs-com";
// import { useMediaQuery } from "react-responsive";
import MetaDecorator from "./MetaDecorator";
import metaThumbnail from "../images/bachericonmetathumbnail.jpg";

function LandingBannerPreview({ objects }) {
  return (
    <div
      className="container-fluid max-width: 100%"
      style={{
        backgroundImage: "url(" + objects.image + ")",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        width: "100%",
        justifyContent: "center",
        verticalAlign: "center",
        alignSelf: "center",
        padding: "50px",
      }}
    >
      <div className="row">
        <div className="col-4">
          <picture>
            <source
              srcset="/assets/images/bachericoncropped.webp"
              type="image/webp"
            />
            <img
              src="/assets/images/bachericoncropped.jpg"
              // height="100%"
              width="100%"
              alt="Bacher Icon Full Black"
            />
          </picture>
        </div>
        <div className="col-8"></div>
      </div>
    </div>
  );
}

class RentalRequestFormPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      requestAnswer: "",
      houseNumber: "",
      apartmentNumber: "",
      name: "",
      email: "",
      phoneNumber: "",
      message: "",
      touched: {
        houseNumber: false,
        apartmentNumber: false,
        phoneNumber: false,
        email: false,
        message: false,
      },
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleResize = (e) => {
    this.setState({ width: window.innerWidth });
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.addEventListener("resize", this.handleResize);
  }

  validate(houseNumber, apartmentNumber, phoneNumber, name, email, message) {
    const errors = {
      houseNumber: "",
      apartmentNumber: "",
      phoneNumber: "",
      name: "",
      email: "",
      message: "",
    };

    const regHouseNum = new RegExp(
      `^[1-${this.props.objects.apartmentOverview.length}]$`
    );
    const regApartmentNum = /^(?:[1-9]|0[1-9]|1[0-9]|2[0-2])$/;
    const regPhoneNum = /^\d+$/;
    const regName = /^[a-zA-Z\s]+$/;

    if (this.state.touched.houseNumber && houseNumber.length === 0) {
      errors.houseNumber = "Dieses Feld darf nicht leer sein.";
    } else if (
      this.state.touched.houseNumber &&
      !regHouseNum.test(houseNumber)
    ) {
      errors.houseNumber =
        "Die Haus Nummer darf nur eine Zahl von 1 bis 3 beinhalten.";
    }

    if (this.state.touched.apartmentNumber && apartmentNumber.length === 0) {
      errors.apartmentNumber = "Dieses Feld darf nicht leer sein.";
    } else if (
      this.state.touched.apartmentNumber &&
      !regApartmentNum.test(apartmentNumber)
    ) {
      errors.apartmentNumber = `Die Wohnungsnummer darf nur Zahlen von 1 bis 22 beinhalten.`;
    }

    if (this.state.touched.phoneNumber && phoneNumber.length === 0) {
      errors.phoneNumber = "Dieses Feld darf nicht leer sein.";
    } else if (
      this.state.touched.phoneNumber &&
      !regPhoneNum.test(phoneNumber)
    ) {
      errors.phoneNumber = "Die Telefonnumer darf nur Zahlen beinhalten.";
    }

    if (this.state.touched.name && name.length === 0) {
      errors.name = "Dieses Feld darf nicht leer sein.";
    } else if (this.state.touched.name && name.replace(/ /g, "").length < 3) {
      errors.name = "Der Name muss aus mindestens 3 Buchstaben bestehen.";
    } else if (this.state.touched.name && !regName.test(name)) {
      errors.name = "Der Name darf nur Buchstaben und Leerzeichen beinhalten.";
    }

    if (this.state.touched.email && email.length === 0) {
      errors.email = "Dieses Feld darf nicht leer sein.";
    } else if (this.state.touched.email && !email.includes("@")) {
      errors.email = "Die E-Mail-Adresse sollte ein @ beinhalten.";
    }

    if (this.state.touched.message && message.length === 0) {
      errors.message = "Dieses Feld darf nicht leer sein.";
    }

    return errors;
  }

  handleBlur = (field) => () => {
    this.setState({
      touched: { ...this.state.touched, [field]: true },
    });
  };

  handleInputChange(event) {
    const target = event.target;
    const name = target.name;
    const value = target.value;

    this.setState({
      [name]: value,
    });
  }

  handleSubmit(event) {
    // console.log("Current state is: " + JSON.stringify(this.state));
    if (
      this.state.houseNumber.length !== 0 &&
      this.state.apartmentNumber.length !== 0 &&
      this.state.name.length !== 0 &&
      this.state.email.length !== 0 &&
      this.state.phoneNumber.length !== 0 &&
      this.state.message.length !== 0
    ) {
      // alert("Current state is: " + JSON.stringify(this.state));

      // init(process.env.REACT_APP_USER_ID);
      emailjs.init(process.env.REACT_APP_PUBLIC_KEY);
      const templateParams = {
        name: this.state.name,
        email: this.state.email,
        house_number: this.state.houseNumber,
        apartment_number: this.state.apartmentNumber,
        phone_number: this.state.phoneNumber,
        message: this.state.message,
      };
      emailjs
        .send(
          process.env.REACT_APP_SERVICE_ID,
          process.env.REACT_APP_RENTALREQUEST_TEMPLATE_ID,
          templateParams
          // process.env.REACT_APP_USER_ID
        )
        .then(
          (response) => {
            this.setState({
              requestAnswer: "success",
            });
            console.log("SUCCESS:", response.status, response.text);
            // alert("Message Sent, We will get back to you shortly", response.text);
          },
          (error) => {
            this.setState({
              requestAnswer: "error",
            });
            console.log("ERROR:", error);
            // alert("An error occurred, Please try again", error.text);
          }
        );
    }
    event.preventDefault();
  }

  render() {
    const isMobile = this.state.width <= 685 ? true : false;
    const isSmallMobile = this.state.width <= 430 ? true : false;

    const errors = this.validate(
      this.state.houseNumber,
      this.state.apartmentNumber,
      this.state.phoneNumber,
      this.state.name,
      this.state.email,
      this.state.message
    );

    const requestAnswer = this.state.requestAnswer;

    return isMobile === true ? (
      <div
        className="container-fluid max-width: 100%"
        style={{
          justifyContent: "center",
          verticalAlign: "center",
          alignSelf: "center",
          padding: "0px",
        }}
      >
        <div className="row" style={{ padding: "0px" }}>
          {isSmallMobile === true ? (
            <React.Fragment />
          ) : (
            <div className="col-1" />
          )}
          <div
            className={isSmallMobile === true ? "col-12" : "col-10"}
            style={{
              // backgroundColor: "#E4E5E6",
              padding: "50px",
            }}
          >
            <h1
              style={{
                color: "black",
                fontWeight: "bold",
                fontFamily: "Avenir",
                textAlign: "left",
                marginTop: "40px",
              }}
            >
              Mietanfrage.
            </h1>
            <h3
              style={{
                color: "black",
                fontFamily: "Avenir",
                textAlign: "left",
                marginTop: "40px",
              }}
            >
              Objekt: {this.props.objects.name}
            </h3>
            <Form onSubmit={this.handleSubmit}>
              <FormGroup row style={{ paddingTop: "10px" }}>
                <Label htmlFor="houseNumber" md={12}>
                  Haus Nummer (Pflichtfeld)
                </Label>
                <Col md={12}>
                  <Input
                    type="text"
                    id="houseNumber"
                    name="houseNumber"
                    placeholder="Haus Nummer"
                    value={this.state.houseNumber}
                    invalid={errors.houseNumber}
                    onBlur={this.handleBlur("houseNumber")}
                    onChange={this.handleInputChange}
                  />
                  <FormFeedback>{errors.houseNumber}</FormFeedback>
                </Col>
              </FormGroup>
              <FormGroup row style={{ paddingTop: "10px" }}>
                <Label htmlFor="apartmentNumber" md={12}>
                  Wohnungsnummer (Pflichtfeld)
                </Label>
                <Col md={12}>
                  <Input
                    type="text"
                    id="apartmentNumber"
                    name="apartmentNumber"
                    placeholder="Wohnungsnummer"
                    value={this.state.apartmentNumber}
                    invalid={errors.apartmentNumber}
                    onBlur={this.handleBlur("apartmentNumber")}
                    onChange={this.handleInputChange}
                  />
                  <FormFeedback>{errors.apartmentNumber}</FormFeedback>
                </Col>
              </FormGroup>
              <FormGroup row style={{ paddingTop: "10px" }}>
                <Label htmlFor="name" md={12}>
                  Name (Pflichtfeld)
                </Label>
                <Col md={12}>
                  <Input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Name"
                    value={this.state.name}
                    invalid={errors.name}
                    onBlur={this.handleBlur("name")}
                    onChange={this.handleInputChange}
                  />
                  <FormFeedback>{errors.name}</FormFeedback>
                </Col>
              </FormGroup>
              <FormGroup row style={{ paddingTop: "10px" }}>
                <Label htmlFor="email" md={12}>
                  E-Mail-Adresse (Pflichtfeld)
                </Label>
                <Col md={12}>
                  <Input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="E-Mail-Adresse"
                    value={this.state.email}
                    invalid={errors.email}
                    onBlur={this.handleBlur("email")}
                    onChange={this.handleInputChange}
                  />
                  <FormFeedback>{errors.email}</FormFeedback>
                </Col>
              </FormGroup>
              <FormGroup row style={{ paddingTop: "10px" }}>
                <Label htmlFor="phoneNumber" md={12}>
                  Telefonnummer (Pflichtfeld)
                </Label>
                <Col md={12}>
                  <Input
                    type="tel"
                    id="phoneNumber"
                    name="phoneNumber"
                    placeholder="Telefonnummer"
                    value={this.state.phoneNumber}
                    invalid={errors.phoneNumber}
                    onBlur={this.handleBlur("phoneNumber")}
                    onChange={this.handleInputChange}
                  />
                  <FormFeedback>{errors.phoneNumber}</FormFeedback>
                </Col>
              </FormGroup>
              <FormGroup row style={{ paddingTop: "10px" }}>
                <Label htmlFor="message" md={12}>
                  Nachricht (Pflichtfeld)
                </Label>
                <Col md={12}>
                  <Input
                    type="textarea"
                    id="message"
                    name="message"
                    rows="4"
                    value={this.state.message}
                    invalid={errors.message}
                    onBlur={this.handleBlur("message")}
                    onChange={this.handleInputChange}
                  />
                  <FormFeedback>{errors.message}</FormFeedback>
                </Col>
              </FormGroup>
              <p style={{ paddingTop: "10px" }}>
                Es gilt unsere{" "}
                <Link to="/privacypolicy" style={{ color: "black" }}>
                  Datenschutzerklärung
                </Link>
                .
              </p>
              {requestAnswer === "success" ? (
                <p style={{ color: "green" }}>
                  Wir haben Ihre Anfrage erhalten und werden uns in Kürze bei
                  Ihnen melden!
                </p>
              ) : requestAnswer === "error" ? (
                <p style={{ color: "red" }}>
                  Leider ist ein Fehler aufgetreten! Bitte versuchen Sie es
                  erneut.
                </p>
              ) : (
                <React.Fragment />
              )}
              <FormGroup row style={{ paddingTop: "10px" }}>
                <Col>
                  <Button
                    type="submit"
                    style={{
                      backgroundColor: "#C83743",
                      borderColor: "#C83743",
                      height: "48px",
                      width: "200px",
                    }}
                    onSubmit={this.handleSubmit}
                  >
                    Absenden
                  </Button>
                </Col>
              </FormGroup>
            </Form>
          </div>
          {isSmallMobile === true ? (
            <React.Fragment />
          ) : (
            <div className="col-1" />
          )}
        </div>
      </div>
    ) : (
      <div
        className="container-fluid max-width: 100%"
        style={{
          justifyContent: "center",
          verticalAlign: "center",
          alignSelf: "center",
          padding: "0px",
        }}
      >
        <div className="row" style={{ padding: "0px" }}>
          <div className="col-1" />
          <div
            className="col-10"
            style={{
              // backgroundColor: "#E4E5E6",
              paddingTop: "50px",
              paddingBottom: "50px",
              paddingLeft: "50px",
              paddingRight: "25px",
            }}
          >
            <h1
              style={{
                color: "black",
                fontWeight: "bold",
                fontFamily: "Avenir",
                textAlign: "left",
                marginTop: "40px",
              }}
            >
              Mietanfrage.
            </h1>
            <h3
              style={{
                color: "black",
                fontFamily: "Avenir",
                textAlign: "left",
                marginTop: "40px",
              }}
            >
              Objekt: {this.props.objects.name}
            </h3>
            <Form onSubmit={this.handleSubmit}>
              <FormGroup row style={{ paddingTop: "10px" }}>
                <Label htmlFor="houseNumber" md={12}>
                  Haus Nummer (Pflichtfeld)
                </Label>
                <Col md={12}>
                  <Input
                    type="text"
                    id="houseNumber"
                    name="houseNumber"
                    placeholder="Haus Nummer"
                    value={this.state.houseNumber}
                    invalid={errors.houseNumber}
                    onBlur={this.handleBlur("houseNumber")}
                    onChange={this.handleInputChange}
                  />
                  <FormFeedback>{errors.houseNumber}</FormFeedback>
                </Col>
              </FormGroup>
              <FormGroup row style={{ paddingTop: "10px" }}>
                <Label htmlFor="apartmentNumber" md={12}>
                  Wohnungsnummer (Pflichtfeld)
                </Label>
                <Col md={12}>
                  <Input
                    type="text"
                    id="apartmentNumber"
                    name="apartmentNumber"
                    placeholder="Wohnungsnummer"
                    value={this.state.apartmentNumber}
                    invalid={errors.apartmentNumber}
                    onBlur={this.handleBlur("apartmentNumber")}
                    onChange={this.handleInputChange}
                  />
                  <FormFeedback>{errors.apartmentNumber}</FormFeedback>
                </Col>
              </FormGroup>
              <FormGroup row style={{ paddingTop: "10px" }}>
                <Label htmlFor="name" md={12}>
                  Name (Pflichtfeld)
                </Label>
                <Col md={12}>
                  <Input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Name"
                    value={this.state.name}
                    invalid={errors.name}
                    onBlur={this.handleBlur("name")}
                    onChange={this.handleInputChange}
                  />
                  <FormFeedback>{errors.name}</FormFeedback>
                </Col>
              </FormGroup>
              <FormGroup row style={{ paddingTop: "10px" }}>
                <Label htmlFor="email" md={12}>
                  E-Mail-Adresse (Pflichtfeld)
                </Label>
                <Col md={12}>
                  <Input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="E-Mail-Adresse"
                    value={this.state.email}
                    invalid={errors.email}
                    onBlur={this.handleBlur("email")}
                    onChange={this.handleInputChange}
                  />
                  <FormFeedback>{errors.email}</FormFeedback>
                </Col>
              </FormGroup>
              <FormGroup row style={{ paddingTop: "10px" }}>
                <Label htmlFor="phoneNumber" md={12}>
                  Telefonnummer (Pflichtfeld)
                </Label>
                <Col md={12}>
                  <Input
                    type="tel"
                    id="phoneNumber"
                    name="phoneNumber"
                    placeholder="Telefonnummer"
                    value={this.state.phoneNumber}
                    invalid={errors.phoneNumber}
                    onBlur={this.handleBlur("phoneNumber")}
                    onChange={this.handleInputChange}
                  />
                  <FormFeedback>{errors.phoneNumber}</FormFeedback>
                </Col>
              </FormGroup>
              <FormGroup row style={{ paddingTop: "10px" }}>
                <Label htmlFor="message" md={12}>
                  Nachricht (Pflichtfeld)
                </Label>
                <Col md={12}>
                  <Input
                    type="textarea"
                    id="message"
                    name="message"
                    rows="4"
                    value={this.state.message}
                    invalid={errors.message}
                    onBlur={this.handleBlur("message")}
                    onChange={this.handleInputChange}
                  />
                  <FormFeedback>{errors.message}</FormFeedback>
                </Col>
              </FormGroup>
              <p style={{ paddingTop: "10px" }}>
                Es gilt unsere{" "}
                <Link to="/privacypolicy" style={{ color: "black" }}>
                  Datenschutzerklärung
                </Link>
                .
              </p>
              {requestAnswer === "success" ? (
                <p style={{ color: "green" }}>
                  Wir haben Ihre Anfrage erhalten und werden uns in Kürze bei
                  Ihnen melden!
                </p>
              ) : requestAnswer === "error" ? (
                <p style={{ color: "red" }}>
                  Leider ist ein Fehler aufgetreten! Bitte versuchen Sie es
                  erneut.
                </p>
              ) : (
                <React.Fragment />
              )}
              <FormGroup row style={{ paddingTop: "10px" }}>
                <Col>
                  <Button
                    type="submit"
                    style={{
                      backgroundColor: "#C83743",
                      borderColor: "#C83743",
                      height: "48px",
                      width: "200px",
                    }}
                    onSubmit={this.handleSubmit}
                  >
                    Absenden
                  </Button>
                </Col>
              </FormGroup>
            </Form>
          </div>
          <div className="col-1" />
        </div>
      </div>
    );
  }
}

class RentalRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div
        className="container-fluid max-width: 100%"
        style={{
          paddingLeft: "0px",
          paddingRight: "0px",
          overflow: "hidden",
        }}
      >
        <MetaDecorator
          title="Mietanfrage - BS-Wohnbau Ingolstadt GmbH"
          description="Ihre neue Mietwohnung wartet bereits."
          imageUrl={metaThumbnail}
          imageAlt="Bacher Icon Complete"
        />
        <LandingBannerPreview objects={this.props.objects} />
        <RentalRequestFormPreview objects={this.props.objects} />
      </div>
    );
  }
}

export default RentalRequest;
