import React, { Component } from "react";

class Privacy extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div
        className="container"
        style={{ paddingTop: "20px", fontFamily: "Avenir" }}
      >
        <h1 style={{ paddingTop: "40px" }}>
          Datenschutzerklärung BS-Wohnbau Ingolstadt GmbH
        </h1>
        <p>Stand: 28. September 2021</p>
        <h2 id="m1">1. Datenschutz auf einen Blick</h2>
        <p>
          <strong>Allgemeine Hinweise</strong>
        </p>
        <p>
          Die folgenden Hinweise geben einen einfachen Überblick darüber, was
          mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website
          besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie
          persönlich identifiziert werden können. Ausführliche Informationen zum
          Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten
          Datenschutzerklärung.
        </p>
        <p>
          <strong>Datenerfassung auf dieser Website</strong>
        </p>
        <p>
          <strong>
            Wer ist verantwortlich für die Datenerfassung auf dieser Website?
          </strong>
          <br />
          Die Datenverarbeitung auf dieser Website erfolgt durch den
          Websitebetreiber. Dessen Kontaktdaten können Sie dem Abschnitt
          „Hinweis zur Verantwortlichen Stelle“ in dieser Datenschutzerklärung
          entnehmen.
        </p>
        <p>
          <strong>Wie erfassen wir Ihre Daten?</strong>
          <br />
          Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
          mitteilen. Hierbei kann es sich z. B. um Daten handeln, die Sie in ein
          Kontaktformular eingeben.
          <br />
          Andere Daten werden automatisch oder nach Ihrer Einwilligung beim
          Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor allem
          technische Daten (z. B. Internetbrowser, Betriebssystem oder Uhrzeit
          des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch,
          sobald Sie diese Website betreten.
        </p>
        <p>
          <strong>Wofür nutzen wir Ihre Daten?</strong>
          <br />
          Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung
          der Website zu gewährleisten. Andere Daten können zur Analyse Ihres
          Nutzerverhaltens verwendet werden.
        </p>
        <p>
          <strong>Welche Rechte haben Sie bezüglich Ihrer Daten?</strong>
          <br />
          Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft,
          Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu
          erhalten. Sie haben außerdem ein Recht, die Berichtigung oder Löschung
          dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur
          Datenverarbeitung erteilt haben, können Sie diese Einwilligung
          jederzeit für die Zukunft widerrufen. Außerdem haben Sie das Recht,
          unter bestimmten Umständen die Einschränkung der Verarbeitung Ihrer
          personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein
          Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
          <br />
          Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich
          jederzeit an uns wenden.
        </p>
        {/* <p>
          Für Datenschutzfragen stehen wir Ihnen gerne via <br />
          <a
            role="button"
            className="btn btn-link"
            href="mailto:hello@lively-shopping.de"
            style={{ padding: "0px" }}
          >
            help@lively-shopping.de
          </a>{" "}
          per E-Mail oder via <br />
          +4916093811285 per Telefon <br />
          zur Verfügung.
        </p>
        <p>
          Vollständige Kontaktdaten finden Sie auch in unserem Impressum{" "}
          <a
            href="https://www.lively-shopping.de/imprint"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.lively-shopping.de/imprint
          </a>
          .
        </p> */}
        <h2 id="m2">2. Hosting und Content Delivery Networks (CDN)</h2>
        <p>
          <strong>Externes Hosting</strong>
          <br />
          Diese Website wird bei einem externen Dienstleister gehostet (Hoster).
          Die personenbezogenen Daten, die auf dieser Website erfasst werden,
          werden auf den Servern des Hosters gespeichert. Hierbei kann es sich
          v. a. um IP-Adressen, Kontaktanfragen, Meta- und Kommunikationsdaten,
          Vertragsdaten, Kontaktdaten, Namen, Websitezugriffe und sonstige
          Daten, die über eine Website generiert werden, handeln.
          <br />
          Der Einsatz des Hosters erfolgt zum Zwecke der Vertragserfüllung
          gegenüber unseren potenziellen und bestehenden Kunden (Art. 6 Abs. 1
          lit. b DSGVO) und im Interesse einer sicheren, schnellen und
          effizienten Bereitstellung unseres Online-Angebots durch einen
          professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO).
          <br />
          Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie dies zur
          Erfüllung seiner Leistungspflichten erforderlich ist und unsere
          Weisungen in Bezug auf diese Daten befolgen.
          <br />
          <br />
          Wir setzen folgenden Hoster ein:
          <br />
          1&1 IONOS SE
          <br />
          Elgendorfer Str. 57
          <br />
          56410 Montabaur
        </p>
        <p>
          <strong>Abschluss eines Vertrages über Auftragsverarbeitung</strong>
          <br />
          Um die datenschutzkonforme Verarbeitung zu gewährleisten, haben wir
          einen Vertrag über Auftragsverarbeitung mit unserem Hoster
          geschlossen.
        </p>
        {/* <p>
          Gemäß der maßgeblichen Datenschutzgrundverordnung haben Sie gegenüber
          der Verantwortlichen folgende gesetzliche Rechte:
        </p>
        <ul>
          <li>Auskunft nach Art. 15 DSGVO</li>
          <li>Berichtigung oder Löschung nach Art. 15 und 16 DSGVO</li>
          <li>Einschränkung der Verarbeitung nach Art. 18 DSGVO</li>
          <li>Recht auf Unterrichtung nach Art. 19 DSGVO</li>
          <li>Widerspruch gegen die Verarbeitung nach Art. 21 DSGVO</li>
          <li>Datenübertragbarkeit im Rahmen von Art. 20 DSGVO</li>
          <li>
            Soweit eine Einwilligung Rechtsgrundlage für die Verarbeitung ist,
            können Sie diese jederzeit mit Wirkung für die Zukunft widerrufen.
          </li>
        </ul>
        <p>
          Darüber hinaus können Sie eine Beschwerde bei der zuständigen
          Datenschutz- Aufsichtsbehörde einlegen, über die Verarbeitung Ihrer
          personenbezogenen Daten durch uns. <br />
          Diese Aufsichtsbehörde erreichen Sie unter:
        </p>
        <br />
        <p>
          Bayerisches Landesamt für Datenschutzaufsicht <br />
          Promenade 27 <br />
          91522 Ansbach <br />
          Telefon: +49 (0) 981 / 53 13 00 <br />
          Telefax: +49 (0) 981 / 53 98 13 00 <br />
          E-Mail: poststelle@lda.bayern.de <br />
          Internet: https://www.lda.bayern.de/de/kontakt.html <br />
        </p>
        */}
        <h2 id="m3">3. Allgemeine Hinweise und Pflichtinformationen</h2>
        <p>
          <strong>Datenschutz</strong>
          <br />
          Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten
          sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und
          entsprechend den gesetzlichen Datenschutzvorschriften sowie dieser
          Datenschutzerklärung.
          <br />
          Wenn Sie diese Website benutzen, werden verschiedene personenbezogene
          Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie
          persönlich identifiziert werden können. Die vorliegende
          Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir
          sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das
          geschieht.
          <br />
          Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B.
          bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann.
          Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist
          nicht möglich.
        </p>
        <p>
          <strong>Hinweis zur verantwortlichen Stelle</strong>
          <br />
          Die verantwortliche Stelle für die Datenverarbeitung auf dieser
          Website ist:
          <br />
          <br />
          BS-Wohnbau Ingolstadt GmbH
          <br />
          Friedrich-Ebert-Straße 18 ¼<br />
          85055 Ingolstadt
          <br />
          <br />
          <strong>Telefon: </strong>0841 95592-0
          <br />
          <strong>E-Mail: </strong>
          <a
            role="button"
            className="btn btn-link"
            href="mailto:info@bsw-in.de"
            style={{ padding: "0px" }}
          >
            info@bsw-in.de
          </a>
          <br />
          <br />
          Verantwortliche Stelle ist die natürliche oder juristische Person, die
          allein oder gemeinsam mit anderen über die Zwecke und Mittel der
          Verarbeitung von personenbezogenen Daten (z. B. Namen, E-Mail-Adressen
          o. Ä.) entscheidet.
        </p>
        <p>
          <strong>Speicherdauer</strong>
          <br />
          Soweit innerhalb dieser Datenschutzerklärung keine speziellere
          Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen Daten
          bei uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie
          ein berechtigtes Löschersuchen geltend machen oder eine Einwilligung
          zur Datenverarbeitung widerrufen, werden Ihre Daten gelöscht, sofern
          wir keine anderen rechtlich zulässigen Gründe für die Speicherung
          Ihrer personenbezogenen Daten haben (z. B. steuer- oder
          handelsrechtliche Aufbewahrungsfristen); im letztgenannten Fall
          erfolgt die Löschung nach Fortfall dieser Gründe.
        </p>
        <p>
          <strong>
            Hinweis zur Datenweitergabe in die USA und sonstige Drittstaaten
          </strong>
          <br />
          Wir verwenden unter anderem Tools von Unternehmen mit Sitz in den USA
          oder sonstigen datenschutzrechtlich nicht sicheren Drittstaaten. Indem
          Sie die jeweiligen Zwecke und Anbieter akzeptieren, willigen Sie
          zugleich gem. Art. 49 Abs. 1 S. 1 lit. a) DSGVO ein, dass Ihre Daten
          möglicherweise in den USA verarbeitet werden. Wenn diese Tools aktiv
          sind, können Ihre personenbezogene Daten in diese Drittstaaten
          übertragen und dort verarbeitet werden. Wir weisen darauf hin, dass in
          diesen Ländern kein mit der EU vergleichbares Datenschutzniveau
          garantiert werden kann. Beispielsweise sind US-Unternehmen dazu
          verpflichtet, personenbezogene Daten an Sicherheitsbehörden
          herauszugeben, ohne dass Sie als Betroffener hiergegen gerichtlich
          vorgehen könnten. Es kann daher nicht ausgeschlossen werden, dass
          US-Behörden (z. B. Geheimdienste) Ihre auf US-Servern befindlichen
          Daten zu Überwachungszwecken verarbeiten, auswerten und dauerhaft
          speichern. Wir haben auf diese Verarbeitungstätigkeiten keinen
          Einfluss.
        </p>
        <p>
          <strong>Widerruf Ihrer Einwilligung zur Datenverarbeitung</strong>
          <br />
          Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
          Einwilligung möglich. Sie können eine bereits erteilte Einwilligung
          jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf
          erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
        </p>
        <p>
          <strong>
            Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie
            gegen Direktwerbung (Art. 21 DSGVO)
          </strong>
          <br />
          Wenn die Datenverarbeitung auf Grundlage von Art. 6 Abs. 1 Lit. E oder
          F DSGVO erfolgt, haben Sie jederzeit das Recht, aus Gründen, die sich
          aus Ihrer besonderen Situation ergeben, gegen die Verarbeitung Ihrer
          personenbezogenen Daten widerspruch einzulegen; dies gilt auch für ein
          auf diese Bestimmungen gestütztes profiling. Die jeweilige
          Rechtsgrundlage, auf denen eine Verarbeitung beruht, entnehmen Sie
          dieser Datenschutzerklärung. Wenn Sie Widerspruch einlegen, werden wir
          Ihre betroffenen personenbezogenen Daten nicht mehr verarbeiten, es
          sei denn, wir können zwingende schutzwürdige Gründe für die
          Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten
          überwiegen oder die Verarbeitung dient der Geltendmachung, Ausübung
          oder Verteidigung von Rechtsansprüchen (Widerspruch nach Art. 21 Abs.
          1 DSGVO).
          <br />
          Werden Ihre personenbezogenen Daten verarbeitet, um Direktwerbung zu
          betreiben, so haben Sie das Recht, jederzeit Widerspruch gegen die
          Verarbeitung Sie betreffender personenbezogener Daten zum Zwecke
          derartiger Werbung einzulegen; dies gilt auch für das profiling,
          soweit es mit solcher Direktwerbung in Verbindung steht. Wenn Sie
          widersprechen, werden Ihre personenbezogenen Daten anschliessend nicht
          mehr zum zwecke der Direktwerbung verwendet (Widerspruch nach Art. 21
          Abs. 2 DSGVO).
        </p>
        <p>
          <strong>Beschwerderecht bei der zuständigen Aufsichtsbehörde</strong>
          <br />
          Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein
          Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem
          Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes
          oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht
          besteht unbeschadet anderweitiger verwaltungsrechtlicher oder
          gerichtlicher Rechtsbehelfe.
        </p>
        <p>
          <strong>Recht auf Datenübertragbarkeit</strong>
          <br />
          Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung
          oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich
          oder an einen Dritten in einem gängigen, maschinenlesbaren Format
          aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an
          einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es
          technisch machbar ist.
        </p>
        <p>
          <strong>SSL- bzw. TLS-Verschlüsselung</strong>
          <br />
          Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der
          Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen oder
          Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL- bzw.
          TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie
          daran, dass die Adresszeile des Browsers von „http://“ auf „https://“
          wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
          <br />
          Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die
          Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen
          werden.
        </p>
        <p>
          <strong>Auskunft, Löschung und Berichtigung</strong>
          <br />
          Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit
          das Recht auf unentgeltliche Auskunft über Ihre gespeicherten
          personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck
          der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder
          Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema
          personenbezogene Daten können Sie sich jederzeit an uns wenden.
        </p>
        <p>
          <strong>Recht auf Einschränkung der Verarbeitung</strong>
          <br />
          Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer
          personenbezogenen Daten zu verlangen. Hierzu können Sie sich jederzeit
          an uns wenden. Das Recht auf Einschränkung der Verarbeitung besteht in
          folgenden Fällen:
          <ul>
            <li>
              Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten
              personenbezogenen Daten bestreiten, benötigen wir in der Regel
              Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie
              das Recht, die Einschränkung der Verarbeitung Ihrer
              personenbezogenen Daten zu verlangen.
            </li>
            <li>
              Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig
              geschah/geschieht, können Sie statt der Löschung die Einschränkung
              der Datenverarbeitung verlangen.
            </li>
            <li>
              Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie
              sie jedoch zur Ausübung, Verteidigung oder Geltendmachung von
              Rechtsansprüchen benötigen, haben Sie das Recht, statt der
              Löschung die Einschränkung der Verarbeitung Ihrer
              personenbezogenen Daten zu verlangen.
            </li>
            <li>
              Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt
              haben, muss eine Abwägung zwischen Ihren und unseren Interessen
              vorgenommen werden. Solange noch nicht feststeht, wessen
              Interessen überwiegen, haben Sie das Recht, die Einschränkung der
              Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
            </li>
          </ul>
          Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt
          haben, dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit
          Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung
          von Rechtsansprüchen oder zum Schutz der Rechte einer anderen
          natürlichen oder juristischen Person oder aus Gründen eines wichtigen
          öffentlichen Interesses der Europäischen Union oder eines
          Mitgliedstaats verarbeitet werden.
        </p>
        <p>
          <strong>Widerspruch gegen Werbe-E-Mails</strong>
          <br />
          Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten
          Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter
          Werbung und Informationsmaterialien wird hiermit widersprochen. Die
          Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im
          Falle der unverlangten Zusendung von Werbeinformationen, etwa durch
          Spam-E-Mails, vor.
        </p>
        <h2 id="m4">4. Datenerfassung auf dieser Website</h2>
        <p>
          <strong>Cookies</strong>
          <br />
          Unsere Internetseiten verwenden so genannte „Cookies“. Cookies sind
          kleine Textdateien und richten auf Ihrem Endgerät keinen Schaden an.
          Sie werden entweder vorübergehend für die Dauer einer Sitzung
          (Session-Cookies) oder dauerhaft (permanente Cookies) auf Ihrem
          Endgerät gespeichert. Session-Cookies werden nach Ende Ihres Besuchs
          automatisch gelöscht. Permanente Cookies bleiben auf Ihrem Endgerät
          gespeichert, bis Sie diese selbst löschen oder eine automatische
          Löschung durch Ihren Webbrowser erfolgt.
          <br />
          <br />
          Teilweise können auch Cookies von Drittunternehmen auf Ihrem Endgerät
          gespeichert werden, wenn Sie unsere Seite betreten
          (Third-Party-Cookies). Diese ermöglichen uns oder Ihnen die Nutzung
          bestimmter Dienstleistungen des Drittunternehmens (z. B. Cookies zur
          Abwicklung von Zahlungsdienstleistungen).
          <br />
          <br />
          Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind
          technisch notwendig, da bestimmte Websitefunktionen ohne diese nicht
          funktionieren würden (z. B. die Warenkorbfunktion oder die Anzeige von
          Videos). Andere Cookies dienen dazu, das Nutzerverhalten auszuwerten
          oder Werbung anzuzeigen.
          <br />
          <br />
          Cookies, die zur Durchführung des elektronischen
          Kommunikationsvorgangs (notwendige Cookies) oder zur Bereitstellung
          bestimmter, von Ihnen erwünschter Funktionen (funktionale Cookies, z.
          B. für die Warenkorbfunktion) oder zur Optimierung der Website (z. B.
          Cookies zur Messung des Webpublikums) erforderlich sind, werden auf
          Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert, sofern keine
          andere Rechtsgrundlage angegeben wird. Der Websitebetreiber hat ein
          berechtigtes Interesse an der Speicherung von Cookies zur technisch
          fehlerfreien und optimierten Bereitstellung seiner Dienste. Sofern
          eine Einwilligung zur Speicherung von Cookies abgefragt wurde, erfolgt
          die Speicherung der betreffenden Cookies ausschließlich auf Grundlage
          dieser Einwilligung (Art. 6 Abs. 1 lit. a DSGVO); die Einwilligung ist
          jederzeit widerrufbar.
          <br />
          <br />
          Sie können Ihren Browser so einstellen, dass Sie über das Setzen von
          Cookies informiert werden und Cookies nur im Einzelfall erlauben, die
          Annahme von Cookies für bestimmte Fälle oder generell ausschließen
          sowie das automatische Löschen der Cookies beim Schließen des Browsers
          aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität
          dieser Website eingeschränkt sein.
          <br />
          <br />
          Soweit Cookies von Drittunternehmen oder zu Analysezwecken eingesetzt
          werden, werden wir Sie hierüber im Rahmen dieser Datenschutzerklärung
          gesondert informieren und ggf. eine Einwilligung abfragen.
        </p>
        <p>
          <strong>Cookie-Einwilligung mit Usercentrics</strong>
          <br />
          Diese Website nutzt die Cookie-Consent-Technologie von Usercentrics,
          um Ihre Einwilligung zur Speicherung bestimmter Cookies auf Ihrem
          Endgerät oder zum Einsatz bestimmter Technologien einzuholen und diese
          datenschutzkonform zu dokumentieren. Anbieter dieser Technologie ist
          die Usercentrics GmbH, Rosental 4, 80331 München, Website:
          https://usercentrics.com/de/ (im Folgenden „Usercentrics“).
          <br />
          <br />
          Wenn Sie unsere Website betreten, werden folgende personenbezogene
          Daten an Usercentrics übertragen:
        </p>
        {/* <br /><br /><br /><br /><br /><br /><br /><br /><br /> */}
        <p>
          <strong>Kontaktformular</strong>
          <br />
          Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre
          Angaben aus dem Anfrageformular inklusive der von Ihnen dort
          angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den
          Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir
          nicht ohne Ihre Einwilligung weiter.
          <br />
          <br />
          Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
          lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags
          zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
          erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf
          unserem berechtigten Interesse an der effektiven Bearbeitung der an
          uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
          Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt
          wurde.
          <br />
          <br />
          Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei
          uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur
          Speicherung widerrufen oder der Zweck für die Datenspeicherung
          entfällt (z. B. nach abgeschlossener Bearbeitung Ihrer Anfrage).
          Zwingende gesetzliche Bestimmungen – insbesondere Aufbewahrungsfristen
          – bleiben unberührt.
        </p>
        <p>
          <strong>Anfrage per E-Mail, Telefon oder Telefax</strong>
          <br />
          Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre
          Anfrage inklusive aller daraus hervorgehenden personenbezogenen Daten
          (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns
          gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre
          Einwilligung weiter.
          <br />
          <br />
          Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
          lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags
          zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
          erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf
          unserem berechtigten Interesse an der effektiven Bearbeitung der an
          uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
          Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt
          wurde.
          <br />
          <br />
          Die von Ihnen an uns per Kontaktanfragen übersandten Daten verbleiben
          bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur
          Speicherung widerrufen oder der Zweck für die Datenspeicherung
          entfällt (z. B. nach abgeschlossener Bearbeitung Ihres Anliegens).
          Zwingende gesetzliche Bestimmungen – insbesondere gesetzliche
          Aufbewahrungsfristen – bleiben unberührt.
        </p>
        <p>
          <strong>Kommentare, Beiträge und Rezensionen</strong>
          <br />
          Die im Rahmen der Kommentare und Beiträge mitgeteilten Informationen
          zur Person, etwaige Kontakt- sowie Webseiteninformationen als auch die
          inhaltlichen Angaben werden von uns bis zum Widerspruch der Nutzer
          dauerhaft gespeichert.
          <ul>
            <li>
              Verarbeitete Datenarten: Bestandsdaten (Vor- und Nachname),
              Kontaktdaten (z.B. E-Mail, Telefonnummern), Inhaltsdaten
              (Rezension).
            </li>
            <li>
              Betroffene Personen: Nutzer (z.B. Webseitenbesucher, Nutzer von
              Onlinediensten).
            </li>
            <li>
              Zwecke der Verarbeitung: Erbringung vertraglicher Leistungen und
              Kundenservice, Feedback (z.B. Sammeln von Feedback via E-Mail
              Anfrage).
            </li>
            <li>
              Rechtsgrundlagen: Vertragserfüllung und vorvertragliche Anfragen
              (Art. 6 Abs. 1 S. 1 lit. b. DSGVO), Berechtigte Interessen (Art. 6
              Abs. 1 S. 1 lit. f. DSGVO).
            </li>
          </ul>
          Die Veröffentlichung Ihrer Rezension erfolgt nach folgender
          Systematik: „[…]Rezension[…]“ – Datum – M. Musterfrau/-mann.
          <br />
          <br />
          Bei Widerruf der Zustimmung wird die Rezension, sowie die oben
          genannten Daten umgehend gelöscht.
        </p>
        <h2 id="m5">5. Plugins und Tools</h2>
        <p>
          <strong>Google Web Fonts (lokales Hosting)</strong>
          <br />
          Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so
          genannte Web Fonts, die von Google bereitgestellt werden. Die Google
          Fonts sind lokal installiert. Eine Verbindung zu Servern von Google
          findet dabei nicht statt.
          <br />
          <br />
          Weitere Informationen zu Google Web Fonts finden Sie unter
          https://developers.google.com/fonts/faq und in der
          Datenschutzerklärung von Google:
          https://policies.google.com/privacy?hl=de.
        </p>
        <p>
          <strong>Google Maps</strong>
          <br />
          Diese Seite nutzt den Kartendienst Google Maps. Anbieter ist die
          Google Ireland Limited („Google“), Gordon House, Barrow Street, Dublin
          4, Irland.
          <br />
          <br />
          Zur Nutzung der Funktionen von Google Maps ist es notwendig, Ihre
          IP-Adresse zu speichern. Diese Informationen werden in der Regel an
          einen Server von Google in den USA übertragen und dort gespeichert.
          Der Anbieter dieser Seite hat keinen Einfluss auf diese
          Datenübertragung. Wenn Google Maps aktiviert ist, kann Google zum
          Zwecke der einheitlichen Darstellung der Schriftarten Google Web Fonts
          verwenden. Beim Aufruf von Google Maps lädt Ihr Browser die benötigten
          Web Fonts in ihren Browsercache, um Texte und Schriftarten korrekt
          anzuzeigen.
          <br />
          <br />
          Die Nutzung von Google Maps erfolgt im Interesse einer ansprechenden
          Darstellung unserer Online-Angebote und an einer leichten
          Auffindbarkeit der von uns auf der Website angegebenen Orte. Dies
          stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f
          DSGVO dar. Sofern eine entsprechende Einwilligung abgefragt wurde,
          erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs.
          1 lit. a DSGVO; die Einwilligung ist jederzeit widerrufbar.
          <br />
          <br />
          Die Datenübertragung in die USA wird auf die Standardvertragsklauseln
          der EU-Kommission gestützt. Details finden Sie hier:
          https://privacy.google.com/businesses/gdprcontrollerterms/ und
          https://privacy.google.com/businesses/gdprcontrollerterms/sccs/.
          <br />
          <br />
          Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der
          Datenschutzerklärung von Google:
          https://policies.google.com/privacy?hl=de.
        </p>
        <h2 id="m6">6. eCommerce und Zahlungsanbieter</h2>
        <p>
          <strong>Verarbeiten von Daten (Kunden- und Vertragsdaten)</strong>
          <br />
          Wir erheben, verarbeiten und nutzen personenbezogene Daten nur, soweit
          sie für die Begründung, inhaltliche Ausgestaltung oder Änderung des
          Rechtsverhältnisses erforderlich sind (Bestandsdaten). Dies erfolgt
          auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung von
          Daten zur Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen
          gestattet. Personenbezogene Daten über die Inanspruchnahme dieser
          Website (Nutzungsdaten) erheben, verarbeiten und nutzen wir nur,
          soweit dies erforderlich ist, um dem Nutzer die Inanspruchnahme des
          Dienstes zu ermöglichen oder abzurechnen.
          <br />
          <br />
          Die erhobenen Kundendaten werden nach Abschluss des Auftrags oder
          Beendigung der Geschäftsbeziehung gelöscht. Gesetzliche
          Aufbewahrungsfristen bleiben unberührt.
        </p>
        <h2 id="m7">7. Eigene Dienste</h2>
        <p>
          <strong>Umgang mit Bewerberdaten</strong>
          <br />
          Wir bieten Ihnen die Möglichkeit, sich bei uns zu bewerben (z. B. per
          E-Mail, postalisch oder via Online-Bewerberformular). Im Folgenden
          informieren wir Sie über Umfang, Zweck und Verwendung Ihrer im Rahmen
          des Bewerbungsprozesses erhobenen personenbezogenen Daten. Wir
          versichern, dass die Erhebung, Verarbeitung und Nutzung Ihrer Daten in
          Übereinstimmung mit geltendem Datenschutzrecht und allen weiteren
          gesetzlichen Bestimmungen erfolgt und Ihre Daten streng vertraulich
          behandelt werden.
        </p>
        <p>
          <strong>Umfang und Zweck der Datenerhebung</strong>
          <br />
          Wenn Sie uns eine Bewerbung zukommen lassen, verarbeiten wir Ihre
          damit verbundenen personenbezogenen Daten (z. B. Kontakt- und
          Kommunikationsdaten, Bewerbungsunterlagen, Notizen im Rahmen von
          Bewerbungsgesprächen etc.), soweit dies zur Entscheidung über die
          Begründung eines Beschäftigungsverhältnisses erforderlich ist.
          Rechtsgrundlage hierfür ist § 26 BDSG-neu nach deutschem Recht
          (Anbahnung eines Beschäftigungsverhältnisses), Art. 6 Abs. 1 lit. b
          DSGVO (allgemeine Vertragsanbahnung) und – sofern Sie eine
          Einwilligung erteilt haben – Art. 6 Abs. 1 lit. a DSGVO. Die
          Einwilligung ist jederzeit widerrufbar. Ihre personenbezogenen Daten
          werden innerhalb unseres Unternehmens ausschließlich an Personen
          weitergegeben, die an der Bearbeitung Ihrer Bewerbung beteiligt sind.
          <br />
          <br />
          Sofern die Bewerbung erfolgreich ist, werden die von Ihnen
          eingereichten Daten auf Grundlage von § 26 BDSG-neu und Art. 6 Abs. 1
          lit. b DSGVO zum Zwecke der Durchführung des
          Beschäftigungsverhältnisses in unseren Datenverarbeitungssystemen
          gespeichert.
        </p>
        <p>
          <strong>Aufbewahrungsdauer der Daten</strong>
          <br />
          Sofern wir Ihnen kein Stellenangebot machen können, Sie ein
          Stellenangebot ablehnen oder Ihre Bewerbung zurückziehen, behalten wir
          uns das Recht vor, die von Ihnen übermittelten Daten auf Grundlage
          unserer berechtigten Interessen (Art. 6 Abs. 1 lit. f DSGVO) bis zu 6
          Monate ab der Beendigung des Bewerbungsverfahrens (Ablehnung oder
          Zurückziehung der Bewerbung) bei uns aufzubewahren. Anschließend
          werden die Daten gelöscht und die physischen Bewerbungsunterlagen
          vernichtet. Die Aufbewahrung dient insbesondere Nachweiszwecken im
          Falle eines Rechtsstreits. Sofern ersichtlich ist, dass die Daten nach
          Ablauf der 6-Monatsfrist erforderlich sein werden (z. B. aufgrund
          eines drohenden oder anhängigen Rechtsstreits), findet eine Löschung
          erst statt, wenn der Zweck für die weitergehende Aufbewahrung
          entfällt.
          <br />
          <br />
          Eine längere Aufbewahrung kann außerdem stattfinden, wenn Sie eine
          entsprechende Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) erteilt haben
          oder wenn gesetzliche Aufbewahrungspflichten der Löschung
          entgegenstehen.
        </p>
        <p>
          <strong>Aufnahme in den Bewerber-Pool</strong>
          <br />
          Sofern wir Ihnen kein Stellenangebot machen, besteht ggf. die
          Möglichkeit, Sie in unseren Bewerber-Pool aufzunehmen. Im Falle der
          Aufnahme werden alle Dokumente und Angaben aus der Bewerbung in den
          Bewerber-Pool übernommen, um Sie im Falle von passenden Vakanzen zu
          kontaktieren.
          <br />
          <br />
          Die Aufnahme in den Bewerber-Pool geschieht ausschließlich auf
          Grundlage Ihrer ausdrücklichen Einwilligung (Art. 6 Abs. 1 lit. a
          DSGVO). Die Abgabe der Einwilligung ist freiwillig und steht in keinem
          Bezug zum laufenden Bewerbungsverfahren. Der Betroffene kann seine
          Einwilligung jederzeit widerrufen. In diesem Falle werden die Daten
          aus dem Bewerber-Pool unwiderruflich gelöscht, sofern keine
          gesetzlichen Aufbewahrungsgründe vorliegen.
          <br />
          <br />
          Die Daten aus dem Bewerber-Pool werden spätestens zwei Jahre nach
          Erteilung der Einwilligung unwiderruflich gelöscht.
        </p>

        {/* <p>
          Für Datenschutzfragen stehen wir Ihnen gerne via <br />
          <a
            role="button"
            className="btn btn-link"
            href="mailto:hello@lively-shopping.de"
            style={{ padding: "0px" }}
          >
            help@lively-shopping.de
          </a>{" "}
          per E-Mail oder via <br />
          +4916093811285 per Telefon <br />
          zur Verfügung.
        </p>
        <p>
          Vollständige Kontaktdaten finden Sie auch in unserem Impressum{" "}
          <a
            href="https://www.lively-shopping.de/imprint"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.lively-shopping.de/imprint
          </a>
          .
        </p> */}

        {/* <p>
          Die App verarbeitet alle technisch erforderlichen Daten, um die
          gewünschte Funktionalität zur Verfügung zu stellen.
        </p>
        <h3>a) als Käufer</h3>
        <p>
          Bei Verwendung der App zum Shopping verarbeiten wir folgende
          personenbezogene Daten:
        </p>
        <ul>
          <li>Name</li>
          <li>Nutzername</li>
          <li>E-Mailadresse</li>
          <li>Telefonnummer</li>
          <li>Geburtsdatum</li>
          <li>Anschrift</li>
          <li>Präferiertes Zahlungsmedium</li>
          <li>Profil- und Hintergrundbild sowie Profilbeschreibung</li>
          <li>Favorisierte Anbieter und Livestreamkategorie</li>
          <li>Bestellhistorie</li>
          <li>
            Bezahlvorgänge einschließlich der auslösenden Livestreams und
            bestellten Produkte
          </li>
        </ul>
        <h3>b) als Verkäufer</h3>
        <p>
          Verwenden Sie die App zum Verkauf Ihrer Waren, verarbeiten wir
          zusätzlich folgende personenbezogenen Daten:
        </p>
        <ul>
          <li>Einordnung als Einzelperson oder Unternehmen</li>
          <li>Umsatzsteueridentifikationsnummer</li>
          <li>Abonnementtyp</li>
          <li>Hochgeladene Livestreams</li>
          <li>Bestellungen</li>
        </ul>
        <h3>c) Cookies und Analytics</h3>
        <p>
          Wir setzen Cookies und Analysetools (Google Analytics mit Firebase)
          ein, um Informationen über die Benutzerzahl und das Benutzerverhalten
          der App zu erhalten. Dies setzen wir ein, um auf dieser Basis die
          Gestaltung der App zu optimieren. Google Analytics setzt Cookies ein,
          die die Analyse der Webseitenbenutzung durch Sie ermöglichen.
        </p>
        <p>
          Sie können dieser Datenverarbeitung jederzeit ohne Nachteile für die
          Benutzung der App widersprechen, indem Sie im Bereich{" "}
          <i>Einstellungen</i> und <i>Sicherheit</i> App Analytics deaktivieren.
        </p>
        <h2 id="m4">4. Datenverwendung</h2>
        <p>
          Die Daten werden im Rahmen des Vertragsschlusses erfasst und
          ausschließlich für die Vertragsdurchführung verwendet (Art. 6 Abs. 1
          lit. a) DSGVO).
        </p>
        <p>
          Falls Sie als Anbieter auftreten, werden wir Ihre Daten auch für Ihr
          Vertragsverhältnis mit Lively Shopping nutzen. In diesem Fall sind
          Teile Ihre personenbezogenen Daten für andere Nutzer von Lively
          Shopping sichtbar, um die Kontaktaufnahme im Rahmen der
          Vertragsabwicklung zu ermöglichen. Sofern Sie sich für unseren
          Newsletter entschieden haben, kontaktieren wir Sie, um Sie über
          Produkte und Angebote zu informieren.
        </p>
        <h2 id="m5">5. Löschung</h2>
        <p>
          Wir speichern Ihre Daten nur solange wie dies zur Erfüllung des
          Verarbeitungszwecks oder gesetzlicher Aufbewahrungsfristen
          erforderlich ist. Um die angemessene Aufbewahrungsfrist festzulegen,
          berücksichtigen wir Art und Sensibilität der personenbezogenen Daten,
          die Zwecke, für die wir sie verarbeiten, und ob wir diese Ziele mit
          anderen Mitteln erreichen können.
        </p> */}
        <h2 id="m8">8. Datenweitergabe</h2>
        <p>
          Im Rahmen der hier dargestellten Datenverwendung geben wir die
          erforderlichen zur Vertragsdurchführung Daten an folgende Dritte
          weiter:
        </p>
        <ul>
          {/* <li>
            Mollie, ein Dienst zur Abwicklung der Bezahlvorgänge, betrieben von
            Mollie BV, Keizersgracht 126, 1015CW Amsterdam, Netherlands. Die
            Datenschutzerklärung findet sich unter{" "}
            <a
              href="https://www.mollie.com/en/privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.mollie.com/en/privacy
            </a>
            .
          </li>
          <li>
            Agora, ein Dienst für die Durchführung von Livestreams, betrieben
            von Agora Lab, Inc. 2804 Mission College Blvd, Suite 110, Santa
            Clara, CA 95054, US. Die Datenschutzerklärung findet sich unter{" "}
            <a
              href="https://www.agora.io/en/privacy-policy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.agora.io/en/privacy-policy/
            </a>
            .
          </li>
          <li>
            Amazon Web Services, für die Speicherung von Profil- und
            Nutzungsdaten, betrieben von Amazon Web Services, Inc. 410 Terry
            Avenue North Seattle WA 98109. United States. Die
            Datenschutzerklärung findet sich unter{" "}
            <a
              href="https://aws.amazon.com/privacy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://aws.amazon.com/privacy/
            </a>
            .
          </li> */}
          <li>
            Firebase, für die Bereitstellung von Analytics, betrieben von Google
            Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland,
            Mutterunternehmen: Google LLC, 1600 Amphitheatre Parkway, Mountain
            View, CA 94043, USA. Die Datenschutzerklärung findet sich unter{" "}
            <a
              href="https://policies.google.com/privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://policies.google.com/privacy
            </a>
            .
          </li>
          <li>
            EmailJS, für das Senden von Emails bei Benutzung unseres
            Kontaktformulars. Die Datenschutzerklärung findet sich unter{" "}
            <a
              href="https://www.emailjs.com/legal/privacy-policy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.emailjs.com/legal/privacy-policy/
            </a>
            .
          </li>
          {/* <li>
            MailChimp, für den Newsletterversand, betrieben von The Rocket
            Science Group , LLC 675 Ponce de Leon Ave. NE, Atlanta, Georgia
            30308, US. Die Datenschutzerklärung findet sich unter{" "}
            <a
              href="https://mailchimp.com/legal/privacy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://mailchimp.com/legal/privacy/
            </a>
            .
          </li>
          <li>
            Mailjet, für den Versand von transaktionalen E-Mails (beispielsweise
            zum Doppel-opt- in für den Newsletter oder zur Accountaktivierung),
            betrieben von Mailjet GmbH, Alt- Moabit 2, 10557 Berlin, Germany,
            Mutterunternehmen: Mailjet SAS, 13-13 bis, rue de l’Aubrac, 75012
            Paris, France. Die Datenschutzerklärung findet sich unter{" "}
            <a
              href="https://www.mailjet.de/sicherheit-datenschutz/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.mailjet.de/sicherheit-datenschutz/
            </a>
            .
          </li>
          <li>
            ShipCloud, ein Dienst für den konventionellen Versand Ihrer
            Bestellungen, shipload GmbH, St. Annenufer 5, 20457 Hamburg,
            Germany. Die Datenschutzerklärung findet sich unter{" "}
            <a
              href="https://www.shipcloud.io/en/privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.shipcloud.io/en/privacy
            </a>
            .
          </li> */}
        </ul>
        <p>
          Hierbei werden Daten an Verarbeiter außerhalb der europäischen Union
          weitergegeben. Es bestehen mit den oben genannten Unternehmen soweit
          erforderlich Auftragsverarbeitungsverträge, die insbesondere die
          Vorgaben der DSGVO auch gegenüber Verarbeitern außerhalb der
          europäischen Union umsetzen.
        </p>
        <div className="container" style={{ height: "50px" }}></div>
      </div>
    );
  }
}

export default Privacy;
