import React, { Component } from "react";
import {
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  NavItem,
} from "reactstrap";
import { NavLink } from "react-router-dom";

class Header extends Component {
  constructor(props) {
    super(props);

    this.toggleNav = this.toggleNav.bind(this);
    this.state = {
      isNavOpen: false,
    };
  }

  toggleNav() {
    this.setState({
      isNavOpen: !this.state.isNavOpen,
    });
  }

  render() {
    return (
      <React.Fragment>
        <Navbar
          light
          style={{ backgroundColor: "white" }}
          sticky="top"
          expand="md"
        >
          <div className="container">
            <NavbarBrand className="mr-auto" href="/">
              <picture>
                <source
                  srcset="/assets/images/bachericoncropped.webp"
                  type="image/webp"
                  height="40"
                />
                <img
                  src="/assets/images/bachericoncropped.jpg"
                  height="40"
                  alt="Bacher Icon"
                />
              </picture>
            </NavbarBrand>
            <NavbarToggler
              onClick={this.toggleNav}
              aria-label="navbar"
              style={{ borderColor: "transparent" }}
            />
            <Collapse isOpen={this.state.isNavOpen} navbar>
              <Nav
                navbar
                className="mx-auto justify-content-center"
                onClick={this.toggleNav}
              >
                <NavItem>
                  <NavLink
                    className="nav-link"
                    to="/home"
                    style={{ fontFamily: "Avenir" }}
                  >
                    Home
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className="nav-link"
                    to="/objects"
                    style={{ fontFamily: "Avenir" }}
                  >
                    Objekte
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className="nav-link"
                    to="/contact"
                    style={{ fontFamily: "Avenir" }}
                  >
                    Kontakt
                  </NavLink>
                </NavItem>
              </Nav>
              <a
                className="btn-outline-color"
                href="https://www.linkedin.com/company/bs-wohnbau-ingolstadt/"
              >
                <i className="fa fa-linkedin fa-md" />{" "}
              </a>
              <a
                className="btn-outline-color"
                href="https://www.facebook.com/bswohnbauingolstadt"
              >
                <i className="fa fa-facebook fa-md" />{" "}
              </a>
              <a
                className="btn-outline-color"
                href="https://www.instagram.com/bswohnbauingolstadt/"
              >
                <i className="fa fa-instagram fa-lg" />{" "}
              </a>
            </Collapse>
          </div>
        </Navbar>
      </React.Fragment>
    );
  }
}

export default Header;
