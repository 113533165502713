export const OBJECTS = [
  {
    id: 0,
    name: "Fraunhofer Str.",
    address: "Fraunhofer Str., 85053 Ingolstadt",
    description: "Wohnen in der Stadt, mit ländlichen Charme",
    link: "fraunhofer-str",
    objectStatus: "rent",
    projectDescription:
      "Im Südosten Ingolstadts im schönen Augustinviertel entstehen 23 Mietwohnungen. Durch die umliegende ruhige Wohnbebauung kommt sogar in der Stadt ein ländlicher Charakter auf. In Zusammenarbeit mit dem renommierten, ortsansässigen Unternehmen Bacher Hoch- und Tiefbau GmbH werden qualitativ, hochwertige Wohnungen in nachhaltiger, massiver Bauweise erstellt. Durch moderne Architektur werden helle Wohnräume mit großzügige Terrassen, Balkone und Gartenflächen realisiert.",
    purchaseReasons: [
      "2 bis 5 Zimmer Wohnungen",
      "33 bis 120 m\u00B2",
      "Zentrale Lage (HBF- und Innenstadtnähe)",
      "Moderne Architektur",
      "Energieeffiziente Bauweise in KfW 40",
      "Qualitative, hochwertige Bauweise",
      "Gartenbereiche mit Terrassen, Balkone oder Dachterrassen",
      "Aufzug",
      "Tiefgaragenstellplätze mit Elektrolademöglichkeiten",
    ],
    objectOutdoorImages: [
      "/assets/images/fraunhoferstroutdoorone.jpg",
      "/assets/images/fraunhoferstroutdoortwo.jpg",
      "/assets/images/fraunhoferstroutdoorthree.jpg",
      "/assets/images/fraunhoferstroutdoorfour.jpg",
    ],
    objectIndoorImages: [
      "/assets/images/fraunhoferstrindoorone.jpg",
      "/assets/images/fraunhoferstrindoortwo.jpg",
    ],
    apartmentOverview: [
      {
        houseNumber: "1",
        images: [
          "/assets/images/fraunhoferstrhouse1floorEG.png",
          "/assets/images/fraunhoferstrhouse1floor1OG.png",
          "/assets/images/fraunhoferstrhouse1floor2OG.png",
        ],
        imagesMobile: [
          "/assets/images/fraunhoferstrhouse1floorEGMobile.png",
          "/assets/images/fraunhoferstrhouse1floor1OGMobile.png",
          "/assets/images/fraunhoferstrhouse1floor2OGMobile.png",
        ],
        link: "fraunhofer-str",
        information: [
          {
            apartmentNumber: "1",
            floor: "EG",
            rooms: "2.5",
            size: "58 m\u00B2",
            coldRent: "928,00 €",
            undergroundParkingSpace: "1",
            parkingRent: "80,00 €",
            status: "vermietet",
          },
          {
            apartmentNumber: "2",
            floor: "EG",
            rooms: "2",
            size: "48 m\u00B2",
            coldRent: "768,00 €",
            undergroundParkingSpace: "1",
            parkingRent: "80,00 €",
            status: "vermietet",
          },
          {
            apartmentNumber: "3",
            floor: "EG",
            rooms: "2",
            size: "39 m\u00B2",
            coldRent: "624,00 €",
            undergroundParkingSpace: "1",
            parkingRent: "80,00 €",
            status: "vermietet",
          },
          {
            apartmentNumber: "4",
            floor: "EG",
            rooms: "3.5",
            size: "80 m\u00B2",
            coldRent: "1.280,00 €",
            undergroundParkingSpace: "2",
            parkingRent: "140,00 €",
            status: "vermietet",
          },
          {
            apartmentNumber: "5",
            floor: "1. OG",
            rooms: "3.5",
            size: "87 m\u00B2",
            coldRent: "1.392,00 €",
            undergroundParkingSpace: "2",
            parkingRent: "140,00 €",
            status: "vermietet",
          },
          {
            apartmentNumber: "6",
            floor: "1. OG",
            rooms: "2",
            size: "33 m\u00B2",
            coldRent: "528,00 €",
            undergroundParkingSpace: "1",
            parkingRent: "80,00 €",
            status: "vermietet",
          },
          {
            apartmentNumber: "7",
            floor: "1. OG",
            rooms: "2",
            size: "60 m\u00B2",
            coldRent: "960,00 €",
            undergroundParkingSpace: "1",
            parkingRent: "80,00 €",
            status: "vermietet",
          },
          {
            apartmentNumber: "8",
            floor: "2. OG",
            rooms: "4.5",
            size: "133 m\u00B2",
            coldRent: "2.128,00 €",
            undergroundParkingSpace: "2",
            parkingRent: "140,00 €",
            status: "vermietet",
          },
        ],
      },
      {
        houseNumber: "2",
        images: [
          "/assets/images/fraunhoferstrhouse2floorEG.png",
          "/assets/images/fraunhoferstrhouse2floor1OG.png",
          "/assets/images/fraunhoferstrhouse2floor2OG.png",
        ],
        imagesMobile: [
          "/assets/images/fraunhoferstrhouse2floorEGMobile.png",
          "/assets/images/fraunhoferstrhouse2floor1OGMobile.png",
          "/assets/images/fraunhoferstrhouse2floor2OGMobile.png",
        ],
        link: "fraunhofer-str",
        information: [
          {
            apartmentNumber: "9",
            floor: "EG",
            rooms: "3",
            size: "78 m\u00B2",
            coldRent: "1.248,00 €",
            undergroundParkingSpace: "2",
            parkingRent: "140,00 €",
            status: "vermietet",
          },
          {
            apartmentNumber: "10",
            floor: "EG",
            rooms: "2",
            size: "39 m\u00B2",
            coldRent: "624,00 €",
            undergroundParkingSpace: "1",
            parkingRent: "80,00 €",
            status: "vermietet",
          },
          {
            apartmentNumber: "11",
            floor: "EG",
            rooms: "3",
            size: "70 m\u00B2",
            coldRent: "1.120,00 €",
            undergroundParkingSpace: "2",
            parkingRent: "140,00 €",
            status: "vermietet",
          },
          {
            apartmentNumber: "12",
            floor: "1. OG",
            rooms: "2.5",
            size: "73 m\u00B2",
            coldRent: "1.168,00 €",
            undergroundParkingSpace: "1",
            parkingRent: "80,00 €",
            status: "vermietet",
          },
          {
            apartmentNumber: "14",
            floor: "1. OG",
            rooms: "2.5",
            size: "70 m\u00B2",
            coldRent: "1.120,00 €",
            undergroundParkingSpace: "1",
            parkingRent: "80,00 €",
            status: "vermietet",
          },
          {
            apartmentNumber: "15",
            floor: "2. OG",
            rooms: "3.5",
            size: "107 m\u00B2",
            coldRent: "1.712,00 €",
            undergroundParkingSpace: "2",
            parkingRent: "140,00 €",
            status: "vermietet",
          },
        ],
      },
      {
        houseNumber: "3",
        images: [
          "/assets/images/fraunhoferstrhouse3floorEG.png",
          "/assets/images/fraunhoferstrhouse3floor1OG.png",
          "/assets/images/fraunhoferstrhouse3floor2OG.png",
        ],
        imagesMobile: [
          "/assets/images/fraunhoferstrhouse3floorEGMobile.png",
          "/assets/images/fraunhoferstrhouse3floor1OGMobile.png",
          "/assets/images/fraunhoferstrhouse3floor2OGMobile.png",
        ],
        link: "fraunhofer-str",
        information: [
          {
            apartmentNumber: "16",
            floor: "EG",
            rooms: "3",
            size: "74 m\u00B2",
            coldRent: "1.184,00 €",
            undergroundParkingSpace: "2",
            parkingRent: "140,00 €",
            status: "vermietet",
          },
          {
            apartmentNumber: "17",
            floor: "EG",
            rooms: "2",
            size: "39 m\u00B2",
            coldRent: "624,00 €",
            undergroundParkingSpace: "1",
            parkingRent: "80,00 €",
            status: "vermietet",
          },
          {
            apartmentNumber: "18",
            floor: "EG",
            rooms: "3",
            size: "70 m\u00B2",
            coldRent: "1.120,00 €",
            undergroundParkingSpace: "2",
            parkingRent: "140,00 €",
            status: "vermietet",
          },
          {
            apartmentNumber: "19",
            floor: "1. OG",
            rooms: "2.5",
            size: "73 m\u00B2",
            coldRent: "1.168,00 €",
            undergroundParkingSpace: "1",
            parkingRent: "80,00 €",
            status: "vermietet",
          },
          {
            apartmentNumber: "21",
            floor: "1. OG",
            rooms: "2.5",
            size: "70 m\u00B2",
            coldRent: "1.120,00 €",
            undergroundParkingSpace: "1",
            parkingRent: "80,00 €",
            status: "vermietet",
          },
          {
            apartmentNumber: "22",
            floor: "2. OG",
            rooms: "3.5",
            size: "108 m\u00B2",
            coldRent: "1.728,00 €",
            undergroundParkingSpace: "2",
            parkingRent: "140,00 €",
            status: "vermietet",
          },
        ],
      },
    ],
    // apartmentStatus: "infofollowing",
    apartmentStatus: "apartmentavailable",
    mapsUrl:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2630.6679174860324!2d11.440057115399613!3d48.75004001628404!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479eff0f3645bf0b%3A0xf4c4879c195904eb!2sFraunhoferstra%C3%9Fe%2C%2085053%20Ingolstadt!5e0!3m2!1sen!2sde!4v1630600294573!5m2!1sen!2sde",
    objectBrochure: "/assets/files/Objektbroschüre_Fraunhofer_Str.pdf",
    imageWebP: "/assets/images/objectsfraunhoferstr.webp",
    image: "/assets/images/objectsfraunhoferstr.jpg",
    height: "100%",
    width: "100%",
    mobileWidth: "320",
    mobileSmallWidth: "300",
  },
  {
    id: 1,
    name: "Oberdolling.",
    address: "Bahnhofstraße 11, 85129 Oberdolling",
    description: "Reihenhäuser im Herzen Oberdollings",
    link: "oberdolling",
    objectStatus: "purchase",
    projectDescription: "",
    purchaseReasons: [],
    objectOutdoorImages: [],
    objectIndoorImages: [],
    apartmentOverview: [],
    apartmentStatus: "infofollowing",
    mapsUrl:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2626.506331072236!2d11.594521315553921!3d48.82947997928467!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479fab9844ac7bf9%3A0x32bca34249bf23cd!2sBahnhofstra%C3%9Fe%2011%2C%2085129%20Oberdolling!5e0!3m2!1sen!2sde!4v1634199333980!5m2!1sen!2sde",
    objectBrochure: "",
    imageWebP: "/assets/images/objectsoberdolling.webp",
    image: "/assets/images/objectsoberdolling.jpg",
    height: "100%",
    width: "100%",
    mobileWidth: "320",
    mobileSmallWidth: "300",
  },
  {
    id: 2,
    name: "Das Gambrinus.",
    address: "Friedrich-Ebert-Straße 32, 85055 Ingolstadt",
    description: "Modernes Wohnen am ehemaligen Gambrinus",
    link: "das-gambrinus",
    objectStatus: "soldout",
    projectDescription:
      "In unmittelbarer Nähe zum Stadtzentrum Ingolstadt entstehen 28 Neubau-Eigentumswohnungen im urbanen Kontext. Der moderne Neubau am Standort der ehemaligen Gaststätte Gambrinus fügt sich hervorragend in die umliegende ruhige Wohnbebauung ein. Durch den Fokus auf großzügige Terrassen, Balkon und Gartenflächen wird ein freundliches Wohnumfeld geschaffen. Ein großzügiges Angebot an Tiefgaragen und Außenstellplätzen runden das exzellente Angebot in unmittelbarer Stadtnähe ab. Weitere Informationen entnehmen Sie unserer detaillierten Baubeschreibung.",
    purchaseReasons: [
      "Ansprechende und individuelle 2 bis 5 Zimmer ETW",
      "45 bis 145 m\u00B2",
      "Moderne Architektur",
      "Energieeffiziente Bauweise in KfW 55",
      "Qualitative, hochwertige Bauweise",
      "Ansprechende und zeitgemäße Ausstattung",
      "Gartenbereiche mit Terrassen, Balkone oder Dachterrassen",
      "Aufzug; viele barrierefreie Wohnungen",
      "Tiefgaragen- und Außenstellplätze in ausreichender Anzahl",
    ],
    objectOutdoorImages: [
      "/assets/images/gambrinusoutdoorone.jpg",
      "/assets/images/gambrinusoutdoortwo.jpg",
      "/assets/images/gambrinusoutdoorthree.jpg",
      "/assets/images/gambrinusoutdoorfour.jpg",
      "/assets/images/gambrinusoutdoorfive.jpg",
      "/assets/images/gambrinusoutdoorsix.jpg",
      "/assets/images/gambrinusoutdoorseven.jpg",
    ],
    objectIndoorImages: [],
    apartmentOverview: [],
    apartmentStatus: "apartmentsold",
    mapsUrl:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2629.5462950617284!2d11.438973915400364!3d48.77146001477807!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479effb46db12753%3A0x3fda4a3ba641104f!2sFriedrich-Ebert-Stra%C3%9Fe%2032%2C%2085055%20Ingolstadt!5e0!3m2!1sen!2sde!4v1630606781325!5m2!1sen!2sde",
    objectBrochure: "",
    imageWebP: "/assets/images/objectsgambrinus.webp",
    image: "/assets/images/objectsgambrinus.jpg",
    height: "100%",
    width: "100%",
    mobileWidth: "320",
    mobileSmallWidth: "300",
  },
];
