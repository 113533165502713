export const REFERENCES = [
  {
    id: 0,
    title:
      "Trotz vieler Unwägbarkeiten der Corona Pandemie, konnten wir im August 2020 in unsere schöne Wohnung einziehen. Danke an das tolle Team der BS-Wohnbau Ingolstadt.",
    imageWebP: "/assets/images/bachericoncomplete.webp",
    image: "/assets/images/bachericoncomplete.jpg",
    imageSmall: "/assets/images/bachericon.jpg",
  },
  {
    id: 1,
    title:
      "Wunderschönes Wohnkonzept. Modern, helle Räume, große Dachterrasse. Einfach Perfekt!",
    imageWebP: "/assets/images/bachericonblackcomplete.webp",
    image: "/assets/images/bachericonblackcomplete.jpg",
    imageSmall: "/assets/images/bachericonblack.jpg",
  },
  {
    id: 2,
    title:
      "Die BS-Wohnbau Ingolstadt ist einfach ein toller Projektpartner. Danke für die gute Zusammenarbeit.",
    imageWebP: "/assets/images/bachericonredcomplete.webp",
    image: "/assets/images/bachericonredcomplete.jpg",
    imageSmall: "/assets/images/bachericonred.jpg",
  },
];
